import React, {FC, useEffect, useState} from "react";
import {useMediaQuery, useThrottledFn, useWindowResize} from "beautiful-react-hooks";
import {breakpoints} from "../../../sematic/breakpoints";
import {DebouncedFunc} from "beautiful-react-hooks/shared/types";
import {GiAlienStare, GiPathDistance, GiPawHeart, GiTeamIdea} from "react-icons/gi";
import {IoIosArrowForward} from "react-icons/io";
import {RaceDataProps, RaceExpandedProps} from "./component/RaceDataProps";
import styled, {css} from "styled-components";



const ContentWrapper = styled.div<RaceExpandedProps>`
  display: flex;
  width: 100%;

  @media screen and (max-width: ${breakpoints.m}px) {
${(props: any) => props?.isExpanded &&
    css`
        flex-direction: column;
      `};
  }
`;

const ToggleRaceWrapper = styled.div<RaceExpandedProps>`
  display: none;

  @media screen and (max-width: ${breakpoints.m}px) {
    display: flex;
    align-items: center;

    & > svg {
      transition: ${({theme: {transition}}) => transition};
    }

    ${(props: any) => props?.isExpanded &&
    css`
        & > svg {
          transform: rotateZ(90deg);
        }
      `};
  }
`;


const RaceWrapper = styled.li<RaceExpandedProps>`
  display: flex;
  padding: 1.5rem 2rem;
  position: relative;
  border-bottom: 1px solid #fe13a0;
  border-bottom-color: black;
  &:after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid ${({theme: {colors}}) => colors.black};
    //transform: skewX(-4deg);
    transition: ${({theme: {transition}}) => transition};
    z-index: -1;
  }

  &:hover {
    &:after {
      background-color: ${({theme: {colors}}) => colors.yellow};
      border-color: transparent;
      box-shadow: ${({theme: {boxShadow}}) => boxShadow};
    }
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: ${breakpoints.m}px) {
    align-items: flex-start;
  }
`;


const RaceDataCaption = styled.h4<RaceExpandedProps>`
  display: none;
  font-weight: normal;

  @media screen and (max-width: ${breakpoints.m}px) {
    margin: 0;
    white-space: nowrap;

    ${(props: any) => props?.isExpanded &&
    css`
        display: block;
        //min-width: 115px;
      `};
  }
`;

const RaceData = styled.div<RaceExpandedProps>`
  display: flex;
  align-items: center;
  font-size: 2rem;

  & > svg {
    font-size: 2rem;
    margin-right: 1rem;
    fill: ${({theme: {colors}}) => colors.grey};
  }

  &:nth-child(1) {
    width: 25%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:nth-child(2) {
    width: calc(25% - 1.5rem);
    margin-left: 1.5rem;
  }

  &:nth-child(3) {
    width: 17%;
  }
  &:nth-child(4) {
    width: 20%;
  }
  &:nth-child(5) {
    width: 13%;
  }

  @media screen and (max-width: ${breakpoints.m}px) {
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      display: none;
    }

    &:nth-child(2) {
      margin-left: 0;
    }

    &:nth-child(1) {
      white-space: nowrap;
      width: 100%;
      flex-grow: 1;
      font-weight: bold;
    }

    ${(props: any) => props?.isExpanded &&
    css`
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          display: flex;
          width: 100%;
          align-items: center;
        }

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      `};
  }
  @media screen and (max-width: ${breakpoints.xs}px) {
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      font-size: 16px;
    }
  }
`;

export const RaceInfoData: FC<RaceDataProps> = ({
                                                raceInfo: {start_date_local, name, location_country, distance, moving_time}
                                            }) => {
    const [isExpanded, setExpanded] = useState(false);
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.m}px)`);
   const allowToBeToggled = isTablet && isExpanded;
    const [width, setWidth] = useState(window.innerWidth);
    const [raceName, setRaceName] = useState(name);

    const MAX_RACE_NAME_LENGTH = 20;

    const onWindowResizeHandler: DebouncedFunc<() => void> = useThrottledFn(() =>
        setWidth(window.innerWidth)
    );

    useWindowResize(onWindowResizeHandler);

    useEffect(() => {
        if (width <= 550) {
            if (name.length > MAX_RACE_NAME_LENGTH) {
                setRaceName(name.slice(0, MAX_RACE_NAME_LENGTH - 3) + '...');
            }
        }
    }, [width, name]);

    const onClickHandler = () => {
        if (isTablet) {
            setExpanded(!isExpanded);
        }
    };

    return (
        <RaceWrapper isExpanded={allowToBeToggled} onClick={onClickHandler}>
            <ContentWrapper isExpanded={allowToBeToggled}>
                <RaceData isExpanded={allowToBeToggled}>{raceName}</RaceData>


                <RaceData isExpanded={allowToBeToggled}>
                    <GiPawHeart/>
                    <RaceDataCaption isExpanded={allowToBeToggled}>Стартовий номер:</RaceDataCaption>
                    {moving_time}
                </RaceData>
                <RaceData isExpanded={allowToBeToggled}>
                    <GiTeamIdea/>
                    <RaceDataCaption isExpanded={allowToBeToggled}>Клуб:</RaceDataCaption>
                    {start_date_local}
                </RaceData>
                <RaceData isExpanded={allowToBeToggled}>
                    <GiPathDistance/>
                    <RaceDataCaption isExpanded={allowToBeToggled}>
                        Дистанція:
                    </RaceDataCaption>
                    {distance} м
                </RaceData>
                <RaceData isExpanded={allowToBeToggled}>
                    <GiAlienStare/>
                    <RaceDataCaption isExpanded={allowToBeToggled}>
                        Стать:
                    </RaceDataCaption>
                    {location_country}
                </RaceData>


            </ContentWrapper>
            <ToggleRaceWrapper isExpanded={allowToBeToggled}>
                <IoIosArrowForward/>
            </ToggleRaceWrapper>
        </RaceWrapper>
    );
};
