import styled from 'styled-components';

export const ContainerBasedOnPaddings = styled.div`
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
  color: ${({theme: {colors}}) => colors.white};

  @media (min-width: 576px) {
    html {
      font-size: 12px;
    }
    max-width: 576px;
  }
  @media (min-width: 768px) {
    html {
      font-size: 14px;
    }
    max-width: 768px;
  }

  @media (min-width: 992px) {
    html {
      font-size: 16px;
    }
    max-width: 992px;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`;

export const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1.5rem 4rem;

  color: ${({theme: {colors}}) => colors.white};

  
  position: relative;
  z-index: 2;
  cursor: pointer;

  font-size: 1.6rem;

  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  //transform: skewY(-4deg);

  &:after {
    display: block;
    content: '';

    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: ${({theme: {colors}}) => colors.black};

    //transition: {({theme}) => theme.transition};
  }

  &:hover {
    color: ${({theme: {colors}}) => colors.white} !important;
    transform: none;
    box-shadow: ${({theme}) => theme.boxShadow};
  }

  &:active {
    //transform: scale(0.98);
  }
`;

export const BurgerMenu = styled.div``;

export const Card = styled.div``;

export const Image = styled.img`
  max-width: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;
