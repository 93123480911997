import React, {FC} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {breakpoints} from "../../../../sematic/breakpoints";
import {Countdown} from "../countdown";
import {colors} from "../../../../sematic/theme";


const HeaderTitle = styled.h2`
  padding: 1.5rem 0.8rem;
  margin-bottom: 1.5rem;
  font-size: 8rem;
  border-top: 5px solid #fe13a0;
  border-bottom: 5px solid #fe13a0;
  letter-spacing: normal;
  border-radius: 0.5rem;
  overflow: hidden;
  text-align: center;
  color: ${props => props.theme.colors.yellow};

  @media (max-width: ${breakpoints.m}px) {
    font-size: 5rem;
  }
`;

const RaceHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  padding: 3.5rem 2rem;
  position: relative;

  &:before {
    display: flex;
    content: '';

    position: absolute;
    z-index: -1;


    height: 35rem;
    width: 200vw;
    left: -100%;
    top: -5rem;


  }

  @media (max-width: ${breakpoints.xs}px) {
    padding: 0 2rem;
  }
`;

const Banner = styled.img`
  display: block;
  width: 100%;
  //height: 34rem;
  max-width: 100%;
  object-fit: cover;
  background-color: ${colors.white};

  position: relative;

  box-shadow: 0 10px 10px 1px rgba(0, 0, 0, 0.3);

  @media (max-width: ${breakpoints.s}px) {
    height: 20rem;
  }
`;

const RaceWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 800px;
  margin-top: 2rem;
  position: relative;

  &:after {
    display: flex;
    content: '';

    position: absolute;
    z-index: -1;


    height: 35rem;
    width: 200vw;
    left: -100%;
  }

  &:after {
    top: 30rem;

  }
`;


const RaceWrapperLink = styled.a`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};

  overflow: hidden;

  &:hover .top {
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.1);
  }
`;

interface RaceCountdownProps {
    /**
     * UserLastRun banner
     */
    banner: string;
    /**
     * UserLastRun title
     */
    title: string;
    url: string;
    /**
     * UserLastRun start date
     */
    startDate: string;
}

const RaceCountDown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;`;

export const RaceCountdown: FC<RaceCountdownProps> = ({
                                                          banner,
                                                          title,
                                                          url,
                                                          startDate
                                                      }) => {
    const timeFormat = 'YYYY-MM-DD HH:mm:ss';
    const parsedDate = moment(startDate).format(timeFormat);

    return (

        <RaceWrapper>
            <RaceHeader>
                <HeaderTitle> <a  href={url}>{title}</a></HeaderTitle>
                <RaceCountDown>
                    <Countdown timeTillDate={parsedDate} timeFormat={timeFormat}/>
                </RaceCountDown>

                <RaceWrapperLink href={url}>
                    <Banner src={banner}/>
                </RaceWrapperLink>
            </RaceHeader>
        </RaceWrapper>
    );
};

