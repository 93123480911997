import React, {FC, useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {ContainerBasedOnPaddings} from '../../../sematic/common';
import api from '../../../axiosApi';

import {InstaGrid} from './InstaGrid';
import {Loader} from '../loader/loader';
import {SectionTitle} from '../../common/section-title';
import {breakpoints} from "../../../sematic/breakpoints";
import {InstagramContent} from "./component/InstagramContent";


const Content = styled(ContainerBasedOnPaddings)<{ uiLoading: boolean }>`
  padding: 1em;
  column-count: 4;

  ${(props: any) =>
    props.uiLoading &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `};
  
  @media (max-width: ${breakpoints.s}px) {
  column-count: 2;
}

`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const InstagramMedia: FC = () => {
    const [mediaProfile, setMediaProfile] = useState<InstagramContent[] | undefined>();
    const [loaded, setLoaded] = useState(false);

    const PHOTOS_TO_SHOW = 16;

    useEffect(() => {
        api
            .getInstagramMedia()
            .then(res => {
                const {data} = res;
                data.length = PHOTOS_TO_SHOW;

                setLoaded(true);
                setMediaProfile(data);
            })
            .catch(e => console.log(e));
    }, []);

    const mediaMapProfile = () =>
        mediaProfile &&
        mediaProfile.map(({post_id, permalink, media_url}) => (
            <InstaGrid key={post_id} permalink={permalink} media_url={media_url} />
        ));

    return (
        <ContentWrapper>
            <SectionTitle direction="ltr">
                наш
                Instagram
            </SectionTitle>
            <Content id="inst" uiLoading={!loaded}>
                {!loaded && !mediaProfile ? (
                    <Loader/>
                ) : (
                    <>{mediaMapProfile()}</>
                )}
            </Content>
        </ContentWrapper>
    );
};
