import React, {FC} from 'react';
import styled from 'styled-components';
import {colors} from '../../../sematic/theme';
import {LoaderProps} from "./component/LoaderProps";
import {Hearts} from "./component/Hearts";

const LoaderContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

`;

export const Loader: FC<LoaderProps> = ({width = 150, heigth = 150}) => (
    <LoaderContent>
        <Hearts color={colors.lrd} height={heigth} width={width} />
    </LoaderContent>
);
