import React, {FC, useCallback, useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import {AiOutlineCaretDown, AiOutlineUser} from 'react-icons/ai';
import 'moment/locale/ru';
import {User} from './component/User';
import {Footer} from '../../common/footer';
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import {SectionTitle} from '../../common/section-title';

import api from '../../../axiosApi';
import {Loader} from '../loader/loader';

import {breakpoints} from '../../../sematic/breakpoints';
import {ContainerBasedOnPaddings} from "../../../sematic/common";
import {HeaderBackground} from "../headerbg/HeaderBackground";

enum SortCriterias {
    byKilometers = 'kilometers',
    byName = 'userNames',
    byClubname = 'club_name'
}

type ComparatorType = 'number' | 'string';
type SortOrder = 'desc' | 'asc';

interface TableColumnsSortOrders {
    userNames: SortOrder;
    kilometers: SortOrder;
    club_name: SortOrder;
}

const defaultSortOrder: TableColumnsSortOrders = {
    userNames: 'asc',
    kilometers: 'asc',
    club_name: 'asc'
};

const AllMembersWrapper = styled.main`
  flex-grow: 1;
`;
const AllMembersContainer = styled(ContainerBasedOnPaddings)`
  @media screen and (max-width: ${`${breakpoints.l}px`}) {
    max-width: 100%;
  }
`;
const Title = styled(SectionTitle)`
  margin-top: 2rem;
`;
const Table = styled.div`
  margin: 3rem 0;
`;
const TableHeader = styled.ul`
  display: flex;
  margin-bottom: 2rem;

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    justify-content: space-between;
  }
`;
const TableHeaderBlock = styled.li<{ sortOrder?: SortOrder }>`
  display: flex;
  align-items: center;
  font-weight: bold;
  transition: ${({theme: {transition}}) => transition};
  cursor: pointer;

  &:nth-child(1) {
    min-width: 245px;
  }
  &:nth-child(2) {
    min-width: 500px;
  }
  &:nth-child(3) {
    min-width: 275px;
  }
  &:nth-child(4) {
    min-width: 160px;
  }

  &:hover {
    opacity: 0.5;
  }

  ${(props: any) => props?.sortOrder &&
    css`
      & > svg {
        margin-left: 1rem;
        transition: ${props?.theme.transition};
        transform: rotateZ(${props?.sortOrder === 'desc' ? 'none' : '180deg'});
      }
      `};
    
   
  @media screen and (max-width: ${`${breakpoints.l}px`}) {
      &:nth-child(1) {
        min-width: 60px;
      }
      
       &:nth-child(2) {
        flex-grow: 1;
        min-width: 0;
      }
  }
  
  @media screen and (max-width: ${`${breakpoints.m}px`}) {
    &:nth-child(1) {
      display: none;
    }
  
    &:nth-child(3) {
      min-width: 180px;
    }
  }
  
   @media screen and (max-width: 670px) {
    &:nth-child(3) {
      display: none;
    }
  }
  
   @media screen and (max-width: ${`${breakpoints.xs}px`}) {
   
    &:nth-child(4) {
      min-width: 0;
    }
   }
`;

const TableBody = styled.ul`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${`${breakpoints.xs}px`}) {
    align-items: normal;
  }
`;


const TableRowWrapper = styled.li`
  &.transition-enter {
    opacity: 0;
    transform: translateY(-100x);
    transition: ${({theme: {transition}}) => transition};
    transition-duration: 2s;
  }

  &.transition-enter-active {
    opacity: 1;
    transform: none;
    transition: ${({theme: {transition}}) => transition};
    transition-duration: 2s;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({theme: {colors}}) => colors.lrd};
  }
`;
const TableRow = styled(Link)`
  display: flex;
  width: 100%;
  padding: 0.5rem;

  &:hover {
    background-color: ${({theme: {colors}}) => colors.yellow};
  }
`;
const TableColumn = styled.div`
  display: flex;
  align-items: center;

  &:nth-child(1) {
    padding-left: 0.5rem;
    min-width: 160px;
  }
  &:nth-child(2) {
    min-width: 580px;
  }
  &:nth-child(3) {
    min-width: 275px;
  }
  &:nth-child(4) {
    min-width: 155px;
  }

  @media screen and (max-width: ${`${breakpoints.l}px`}) {
    &:nth-child(1) {
      min-width: 60px;
    }
    &:nth-child(2) {
      flex-grow: 1;
      min-width: 0;
    }
  }

  @media screen and (max-width: ${`${breakpoints.m}px`}) {
    &:nth-child(1) {
      display: none;
    }

    &:nth-child(2) {
      flex-grow: 1;
      min-width: 0;
    }

    &:nth-child(3) {
      min-width: 180px;
    }
  }
  @media screen and (max-width: 670px) {
    &:nth-child(3) {
      display: none;
    }
  }

  @media screen and (max-width: ${`${breakpoints.xs}px`}) {
    &:nth-child(4) {
      min-width: 0;
    }
  }

  @media screen and (max-width: 375px) {
    &:nth-child(2) > .fullname {
      max-width: 130px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;
const PhotoPlaceholder = styled(AiOutlineUser)`
  font-size: 3rem;
 ${(props: any) => props?.theme && css`
     color: ${props?.theme.colors.black};
      `};
`;


const UserPhoto = styled.img`
  width: 100%;
  max-width: 100%;
  object-fit: cover;
`;
const UserPhotoWrapper = styled.div<{ hasPhoto: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-right: 2rem;
  border-radius: 50%;
  overflow: hidden;

  box-shadow: ${({theme: {boxShadow}}) => boxShadow};
  transition: ${({theme: {transition}}) => transition};

  background: ${({hasPhoto, theme: {colors}}) =>
    !hasPhoto ? colors.white : 'none'};

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    width: 4rem;
    height: 4rem;
  }
`;


const UserFullName = styled.span `
  @media screen and (max-width: ${`${breakpoints.s}px`}) {  letter-spacing: 0;  }
`;
export function useForceUpdate() {
    const [, setTick] = useState(0);
    return useCallback(() => {
        setTick(tick => tick + 1);
    }, []);
}


export const AllUsers: FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [sortOrders, setSortOrders] = useState<TableColumnsSortOrders>(
        defaultSortOrder
    );
    const [loaded, setLoaded] = useState(true);
    const forceUpdate = useForceUpdate();


    useEffect(() => {
        if (loaded) {
            api.getAllMembers().then(({data}) => {
                setUsers(data);
                setLoaded(false);
            });
        }
    });

    const compareNumbers = (a: number, b: number): number => a - b;
    const compareStrings = (a: string, b: string): number =>
        a > b ? 1 : a < b ? -1 : 0;

    const getComparator = (
        order: SortOrder,
        type: ComparatorType
    ): ((a: any, b: any) => number) => {
        if (order === 'desc') {
            return type === 'number'
                ? (a: number, b: number): number => compareNumbers(a, b)
                : (a: string, b: string): number => compareStrings(a, b);
        } else {
            return type === 'number'
                ? (a: number, b: number): number => compareNumbers(b, a)
                : (a: string, b: string): number => compareStrings(b, a);
        }
    };
    const sortBy = (sortCriteria: SortCriterias) => {
        const order = sortOrders[sortCriteria];
        const updatedSortOrders = {
            ...sortOrders,
            [sortCriteria]: order === 'desc' ? 'asc' : 'desc'
        };
        const comparatorType =
            sortCriteria === SortCriterias.byKilometers ? 'number' : 'string';
        const comparator = getComparator(order, comparatorType);

        // eslint-disable-next-line
        users.sort((u1: User, u2: User): number => {
            const {byKilometers, byName, byClubname} = SortCriterias;
            switch (sortCriteria) {
                case byKilometers:
                    return comparator(u1.distance, u2.distance);
                case byClubname:
                    return comparator(u1.club, u2.club);
                case byName:
                    return comparator(u1.athlete_lastname, u2.athlete_lastname);
            }
        });

        setSortOrders(updatedSortOrders);
        forceUpdate();
    };

    const sortByName = () => sortBy(SortCriterias.byName);
    const sortByClub = () => sortBy(SortCriterias.byClubname);
    const sortByKilometers = () => sortBy(SortCriterias.byKilometers);

    return (
        <>
            <HeaderBackground/>
            <AllMembersWrapper>
                <Title direction="ltr">Рейтинг тижня</Title>
                <AllMembersContainer>
                    <Table>
                        <TableHeader>
                            <TableHeaderBlock>№</TableHeaderBlock>
                            <TableHeaderBlock
                                onClick={sortByName}
                                sortOrder={sortOrders.userNames}
                            >
                                Імя <AiOutlineCaretDown/>
                            </TableHeaderBlock>
                            <TableHeaderBlock
                                onClick={sortByClub}
                                sortOrder={sortOrders.club_name}
                            >
                                Клуб <AiOutlineCaretDown/>
                            </TableHeaderBlock>
                            <TableHeaderBlock
                                onClick={sortByKilometers}
                                sortOrder={sortOrders.kilometers}
                            >
                                Км за тиждень <AiOutlineCaretDown/>
                            </TableHeaderBlock>
                        </TableHeader>
                        {loaded ? (
                            <Loader/>
                        ) : (
                            <TransitionGroup component={TableBody}>

                                {users.map((user, index) => {
                                    const {
                                        athlete_id,
                                        distance,
                                        athlete_firstname,
                                        athlete_lastname,
                                        club,
                                        athlete_picture_url
                                    } = user;
                                    const hasAvatar = athlete_picture_url !== null;
                                    return (
                                        <CSSTransition
                                            key={athlete_id}
                                            appear={false}
                                            enter={true}
                                            exit={false}
                                            timeout={1000}
                                        >
                                            <TableRowWrapper key={athlete_id}>
                                                <TableRow to={`/users/${athlete_id}/info`}>
                                                    <TableColumn>{index + 1}</TableColumn>
                                                    <TableColumn>
                                                        <UserPhotoWrapper hasPhoto={hasAvatar}>
                                                            {athlete_picture_url ? (
                                                                <UserPhoto src={athlete_picture_url}/>
                                                            ) : (
                                                                <PhotoPlaceholder/>
                                                            )}
                                                        </UserPhotoWrapper>
                                                        <UserFullName>{athlete_firstname} {athlete_lastname}</UserFullName>
                                                    </TableColumn>


                                                    <TableColumn>{club} </TableColumn>
                                                    <TableColumn>{distance} км </TableColumn>
                                                </TableRow>
                                            </TableRowWrapper>
                                        </CSSTransition>
                                    );
                                })}
                            </TransitionGroup>
                        )}
                    </Table>
                </AllMembersContainer>
            </AllMembersWrapper>
            <Footer/>
        </>
    );
};
