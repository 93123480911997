import React, {FC, useEffect, useState} from 'react';
import 'moment/locale/ru';
import {Footer} from '../../../common/footer';
import api from '../../../../axiosApi';
import {HeaderBackground} from "../../headerbg/HeaderBackground";
import {Loader} from "../../loader/loader";
import {SectionTitle} from "../../../common/section-title";
import styled, {css} from "styled-components";
import {ContainerBasedOnPaddings} from "../../../../sematic/common";
import {breakpoints} from "../../../../sematic/breakpoints";
import {ClubList} from "../ClubList";
import {ClubContent} from "../component/ClubContent";



const Content = styled(ContainerBasedOnPaddings)<{ uiLoading: boolean }>`
  padding: 1em;
  column-count: 2;

  ${(props: any) =>
    props.uiLoading &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `};
  
  @media (max-width: ${breakpoints.s}px) {
    column-count: 1;
}

`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const Clubs: FC = () => {
    const [clubFeed, setClub] = useState<ClubContent[] | undefined>();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        api
            .getClubs()
            .then(res => {
                const {data} = res;

                setLoaded(true);
                setClub(data);
            })
            .catch(e => console.log(e));
    }, []);

    const formatInstaPosts = () =>
        clubFeed &&
        clubFeed.map(({id, name,runners, club_avatar}) => (
            <ClubList key={id} permalink={id} club_name={name} runners={runners} club_avatar={club_avatar} />
        ));


    return (
        <ContentWrapper>
            <HeaderBackground/>

            <SectionTitle direction="ltr">
                Клуби
            </SectionTitle>
            <Content id="inst" uiLoading={!loaded}>
                {!loaded && !clubFeed ? (
                    <Loader/>
                ) : (
                    <>{formatInstaPosts()}</>
                )}
            </Content>

            <Footer/>
        </ContentWrapper>
    );
};
