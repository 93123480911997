import React, {FC, useEffect, useRef, useState} from 'react';
import moment from 'moment';
import '../../../assets/test.css';
import Timer from "./Timer";
import {CountdownProps} from "./component/CountdownProps";



export const Countdown: FC<CountdownProps> = ({timeTillDate, timeFormat}) => {
    const [days, setDays] = useState<number>();
    const [hours, setHours] = useState<number>();
    const [minutes, setMinutes] = useState<number>();
    const [seconds, setSeconds] = useState<number>();



    const interval: any = useRef(-1);

    useEffect(() => {
        interval.current = setInterval(() => {

            const then: any = moment(timeTillDate, timeFormat);
            const now: any = moment(Date.now());

            const countdown = moment.duration(then.diff(now));

            const days = countdown.days() + countdown.months() * 31;
            const hours = countdown.hours();
            const minutes = countdown.minutes();
            const seconds = countdown.seconds();


            setDays(days);
            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);

        }, 1000);

        return () => {
            if (interval.current) {
                clearInterval(interval.current);
            }
        };
    }, [timeFormat, timeTillDate]);

    return (
        <>
            <Timer time={'Днів'} count={days}  />
            <Timer time={'Годин'} count={hours}  />
            <Timer time={'Хвилин'} count={minutes} />
            <Timer time={'Секунд'} count={seconds} />
        </>
    );
};
