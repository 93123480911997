import {createGlobalStyle} from 'styled-components';
import {normalize} from './normalize';
import {vendor} from './vendor';
import {breakpoints} from './breakpoints';


export const GlobalStyle = createGlobalStyle`
  
  *,
  *::before,
  *::after {
    
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }

  select,
  button,
  input,
  textarea {
    background: unset;
    border: 0;
    outline: 0;
    padding: 0;
  }

  html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; 
  }

  ${normalize}

  ;
  ${vendor}

  ;
  html,
  body {
    height: 100%;
    background-color: #151515;
    font-family: 'Red Hat Text', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: uppercase; 
    letter-spacing: .3rem;
    //text-align: center;
  }

  html {
    box-sizing: border-box;
    font-size: 50%;

    @media only screen and (max-width: ${breakpoints.l}) {
      font-size: 55%;
    }
  }

  body {
    overflow-x: hidden;

    font-size: 1.9rem;
    font-weight: 400;
  }

  //Reset default styles of a list
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a:link,
  a:active,
  a:visited {
    
    text-decoration: none;
  }

  /* Force Simple Scrollbars */
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #fe13a0;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: black;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgb(254, 211, 0);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: black;
  }

  /*******************************
              Headers
  *******************************/

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Red Hat Text',sans-serif;
    font-style: normal;
    font-weight: 700;
   }

  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child {
    margin-top: 0em;
  }

  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child {
    margin-bottom: 0em;
  }

  /*******************************
              Text
  *******************************/

  /*-------------------
          Links
  --------------------*/

  a,
  a:hover {
    color: inherit;
    text-decoration: none;
  }

 
`;
