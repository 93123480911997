import React, {FC} from 'react';
import styled from 'styled-components';

import {NavBar} from '../burger_menu/NavBar';
import {NavigationProps} from "./component/NavigationProps";
import {NavigationListWrapper} from "./navigationList";

const NavigationWrapper = styled.nav<NavigationProps>` 
  display: flex;
  align-items: center;
`;



export const Navigation: FC<NavigationProps> = ({
                                                    isScrolled,
                                                    isMobileView,
                                                    onBurgerClick,
                                                    isBurgerOpen,
                                                    activePage
                                                }) => {

    return (
        <NavigationWrapper isMobileView={isMobileView}>
            <>
                {!isMobileView ? (
                    <NavigationListWrapper
                        isScrolled={isScrolled}
                        isMobileView={isMobileView}
                        activePage={activePage}
                    />
                ) : (
                    <NavBar
                        onClickHandler={onBurgerClick!}
                        isOpen={isBurgerOpen!}
                        isScrolled={isScrolled}
                    />
                    )}
            </>
        </NavigationWrapper>
    );
};
