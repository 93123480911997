import React, {FC} from "react";
import styled, {css} from "styled-components";
import {breakpoints} from "../../../sematic/breakpoints";



const TitleContentRun = styled.div`
  //display: flex;
  align-items: center;
  position: relative;
  //width: 100vw;
  padding: 2rem 0; 
  color: white;
 // color: transparent;
  font-weight: bold;
  text-transform: uppercase;

 

  &:before,
  &:after {
    display: block;
    content: '';

    height: 6px;
    width: 100%; 
    position: relative;

  }
 
  @media (max-width: ${breakpoints.s}px) {
    //display: none;

    &:before,
    &:after {
      display: block;
      content: '';

      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }

 
  }
`;

const TitleRunWrapper = styled.div `
  //padding: 2rem 4rem;
  //padding: 20rem 4rem;
  font-size: 12rem;
  text-align: center;

  ${(props: any) => props.inOneLine &&
    css`
      white-space: nowrap;
    `};
 
  > svg {
    font-size: 6rem;
  }
`;

export const TitleContentSimple: FC = ({
                                                              children
                                                          }) => (
    <TitleContentRun>
        <TitleRunWrapper>
            {children}
        </TitleRunWrapper>
    </TitleContentRun>
);
