import {css} from "styled-components";

import {breakpoints} from "./breakpoints";
import paw from "../assets/LrdLogo.svg";
export const vendor: any = css`
  .slick {
    &-dots {
      bottom: -5px;

      li {
        button {
          &::before {
            content: "";
            border-radius: 50%;
            background-color: ${props => props.theme.colors.black};
            opacity: 1;
            transition: ${props => props.theme.transition};
            width: 15px;
            height: 15px;
          }
        }
        &.slick-active {
          button {
            &::before {
              border: 4px solid ${props => props.theme.colors.black};
              opacity: 1;
            }
          }
        }
      }
    }

    &-slide {
      padding: 8px;
    }

    &-arrow {
      height: 50px;
      width: 50px;
      z-index: 10;

      &::before {
        display: flex;
        content: "";

        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;

        background: url(${paw}) no-repeat center;
        background-size: cover;
      }
    }

    &-prev {
      left: -50px;
      top: calc(50%);
      transform: translateY(-25px);

      @media (max-width: ${breakpoints.xs}px) {
        left: 50%;
        top: calc(100% + 20px);
        transform: translate3d(calc(-50% - 30px), 0, 0);
      }
    }

    &-next {
      transform: rotateZ(180deg) translateY(25px);
      top: calc(50%);
      right: -50px;

      @media (max-width: ${breakpoints.xs}px) {
        right: 50%;
        top: calc(100% + 20px);
        transform: rotateZ(180deg) translate3d(calc(-50% - 30px), 0, 0);
      }
    }

    &-list {
      padding: 20px 0;
      margin: 0 15px;

      @media (max-width: ${breakpoints.xs}px) {
        margin-bottom: 4rem;
      }
    }
  }
`;
