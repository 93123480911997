import {breakpoints} from '../sematic/breakpoints';

export const COMMON_SLIDER_SETTINGS = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: breakpoints.m,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: breakpoints.xs,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true
            }
        }
    ]
};

export const SCROLL_CONFIGURATION = {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart'
};
