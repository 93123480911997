import React, {FC} from 'react';
import styled, {css} from 'styled-components';
import {NavBarComponent} from "./component/NavBarComponent";
import {NavBarProps} from "./component/NavBarProps";

const BurgerWrapper = styled.div<NavBarComponent>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-end;
  cursor: pointer;
  width: 4rem;
  height: 3rem;
  transition: ${props => props.theme.transition};

  ${(props: any) => props.isScrolled &&
          css`
            > div {
              background-color: ${props => props.theme.colors.lrd}; //todo цвет бургера на стике
            }
          `};

  ${(props: any) =>
          props.isOpen &&
          css`
            transform: none;

            & > div {
              &:nth-child(1) {
                width: 50%;
              }

              &:nth-child(2) {
                opacity: 100%;
              }

              &:nth-child(3) {
                width: 50%;
              }
          `}

`;

const Line = styled.div`
  height: 0.5rem;
  background-color: ${props => props.theme.colors.lrd};;
  width: 100%;

  transition: ${props => props.theme.transition};

  &:nth-child(2) {
    width: 150%;
  }

  &:nth-child(3) {
    width: 100%;
  }
`;


export const NavBar: FC<NavBarProps> = ({onClickHandler, isOpen, isScrolled }) => {
    return (
        <BurgerWrapper isOpen={isOpen} onClick={onClickHandler} isScrolled={isScrolled} >
            <Line/>
            <Line/>
            <Line/>
        </BurgerWrapper>
    );
};
