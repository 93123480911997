import React, {FC, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {FaFacebook, FaFacebookF, FaInstagram, FaRegUser, FaStrava, FaTelegram} from 'react-icons/fa';
import moment from 'moment';

import api from '../../../../axiosApi';
import {Loader} from '../../loader/loader';
import {Footer} from '../../../common/footer';

import {SectionTitle} from '../../../common/section-title';
import {breakpoints} from '../../../../sematic/breakpoints';
import {ContainerBasedOnPaddings} from '../../../../sematic/common';
import {HeaderBackground} from "../../headerbg/HeaderBackground";
import {AiOutlineCaretDown, AiOutlineUser} from "react-icons/ai";
import {colors} from "../../../../sematic/theme";
import {TransitionGroup} from "react-transition-group";

moment.locale('ru');

interface Users {
    id: string;
    name: string;
    avatar: string;
    activities: string;
    distance: string;
}

interface ClubInfoBase {
    name: string;
    club_avatar: string;
    club_link: string;
    club_inst: string;
    club_fb: string;
    club_tg: string;
    runners: string;
    users: Users[];
}

type ClubInfo = ClubInfoBase | undefined;

const UserInfoContainer = styled(ContainerBasedOnPaddings)`
  margin-top: 5rem;
  margin-bottom: 5rem;
`;
const UserInfoWrapper = styled.main`
  flex-grow: 1;
`;
const InfoWrapper = styled.section`
  display: flex;
  flex-direction: column;

  > span:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
const Title = styled.h2`
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 1rem;

  @media screen and (max-width: ${breakpoints.xs}px) {
    text-align: center;
  }
`;

const MainInfo = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${breakpoints.m}px) {
    flex-direction: column;
  }
`;
const MainInfoLeft = styled.div`
  display: flex;
  border-left: 10px solid #fe13a0;
  border-bottom: 5px solid #fe13a0;
  @media screen and (max-width: ${breakpoints.m}px) {
    justify-content: space-between;
  }

  @media screen and (max-width: ${breakpoints.xs}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PhotoAndSocialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const SocialsList = styled.ul`
  display: flex;
  margin-top: 2rem;

  @media screen and (max-width: ${breakpoints.xs}px) {
    justify-content: center;
  }
`;
const SocialsListItem = styled.li`
  margin-right: 2rem;
`;
const SocialsListLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fe13a0; 
color: #151515;
  height: 9rem;
  font-size: 35px;
  width: 9rem;
  border-radius: 50%;
  transition: ${({theme: {transition}}) => transition};

  &:hover {
    color: ${({theme: {colors}}) => colors.red};
  }
`;

const SecondaryInfo = styled.div`
  display: flex;
  margin-top: 9rem;

  @media screen and (max-width: ${breakpoints.xs}px) {
    flex-direction: column;
    margin-top: 4rem;
  }
`;
const SecondaryInfoBlock = styled.div`
  border-left: 10px solid ${({theme: {colors}}) => colors.yellow};
  border-bottom: 5px solid ${({theme: {colors}}) => colors.yellow};
  display: flex;
  flex-direction: column;
  padding-left: 1rem;

  &:not(:last-child) {
    margin-right: 7rem;
  }

  @media screen and (max-width: ${breakpoints.xs}px) {
    &:not(:last-child) {
      margin-bottom: 2rem;
      margin-right: 0;
    }
  }
`;
const SecondaryInfoBlockTitle = styled.h3`
  font-weight: normal;
  margin-bottom: 1rem;
  font-size: 2.4rem;
`;
const SecondaryInfoBlockValue = styled.span`
  font-weight: bold;
  font-size: 3rem;
`;
const RaceResults = styled(ContainerBasedOnPaddings)`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.m}px) {
    max-width: 100%;
  }
`;
const RacesWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
  border-bottom: 1px solid #fe13a0;
`;



const TableRowWrapper = styled.li`
  &.transition-enter {
    opacity: 0;
    transform: translateY(-100x);
    transition: ${({theme: {transition}}) => transition};
    transition-duration: 2s;
  }

  &.transition-enter-active {
    opacity: 1;
    transform: none;
    transition: ${({theme: {transition}}) => transition};
    transition-duration: 2s;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({theme: {colors}}) => colors.lrd};
  }
`;
const TableRow = styled(Link)`
  display: flex;
  width: 100%;
  padding: 0.5rem;

  &:hover {
    background-color: ${({theme: {colors}}) => colors.yellow};
  }
`;
const TableColumn = styled.div`
  display: flex;
  align-items: center;

  &:nth-child(1) {
    padding-left: 0.5rem;
    min-width: 160px;
  }
  &:nth-child(2) {
    min-width: 580px;
  }
  &:nth-child(3) {
    min-width: 275px;
  }
  &:nth-child(4) {
    min-width: 155px;
  }

  @media screen and (max-width: ${`${breakpoints.l}px`}) {
    &:nth-child(1) {
      min-width: 60px;
    }
    &:nth-child(2) {
      flex-grow: 1;
      min-width: 0;
    }
  }

  @media screen and (max-width: ${`${breakpoints.m}px`}) {
    &:nth-child(1) {
      display: none;
    }

    &:nth-child(2) {
      flex-grow: 1;
      min-width: 0;
    }

    &:nth-child(3) {
      min-width: 180px;
    }
  }
  @media screen and (max-width: 670px) {
    &:nth-child(3) {
      display: none;
    }
  }

  @media screen and (max-width: ${`${breakpoints.xs}px`}) {
    &:nth-child(4) {
      min-width: 0;
    }
  }

  @media screen and (max-width: 375px) {
    &:nth-child(2) > .fullname {
      max-width: 130px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;
const PhotoPlaceholder = styled(FaRegUser)`
  font-size: 3rem;
 ${(props: any) => props?.theme && css`
     color: ${props?.theme.colors.white};
      `};
`;


const UserPhoto = styled.img`
  width: 100%;
  max-width: 100%;
  object-fit: cover;
`;


const UserPhotoWrapper = styled.div<{ hasPhoto: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-right: 2rem;
  border-radius: 50%;
  overflow: hidden;

  box-shadow: ${({theme: {boxShadow}}) => boxShadow};
  transition: ${({theme: {transition}}) => transition};

  background: ${({hasPhoto, theme: {colors}}) =>
    !hasPhoto ? colors.white : 'none'};

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    width: 4rem;
    height: 4rem;
  }
`;

const Table = styled.div`
  margin: 3rem 0;
`;
const TableHeader = styled.ul`
  display: flex;
  margin-bottom: 2rem;

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    justify-content: space-between;
  }
`;
const TableHeaderBlock = styled.li`
  display: flex;
  align-items: center;
  font-weight: bold;
  transition: ${({theme: {transition}}) => transition};
  cursor: pointer;

  &:nth-child(1) {
    min-width: 245px;
  }
  &:nth-child(2) {
    min-width: 500px;
  }
  &:nth-child(3) {
    min-width: 275px;
  }
  &:nth-child(4) {
    min-width: 160px;
  }

  &:hover {
    opacity: 0.5;
  }

  ${(props: any) => props?.sortOrder &&
    css`
      & > svg {
        margin-left: 1rem;
        transition: ${props?.theme.transition};
        transform: rotateZ(${props?.sortOrder === 'desc' ? 'none' : '180deg'});
      }
      `};
    
   
  @media screen and (max-width: ${`${breakpoints.l}px`}) {
      &:nth-child(1) {
        min-width: 60px;
      }
      
       &:nth-child(2) {
        flex-grow: 1;
        min-width: 0;
      }
  }
  
  @media screen and (max-width: ${`${breakpoints.m}px`}) {
    &:nth-child(1) {
      display: none;
    }
  
    &:nth-child(3) {
      min-width: 180px;
    }
  }
  
   @media screen and (max-width: 670px) {
    &:nth-child(3) {
      display: none;
    }
  }
  
   @media screen and (max-width: ${`${breakpoints.xs}px`}) {
   
    &:nth-child(4) {
      min-width: 0;
    }
   }
`;

const TableBody = styled.ul`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${`${breakpoints.xs}px`}) {
    align-items: normal;
  }
`;


export const ClubInfo: FC = () => {
    const [clubInfo, setClubInfo] = useState<ClubInfo>();
    const [loaded, setLoaded] = useState(true);
    const {id} = useParams();

    useEffect(() => {
        if (loaded) {
            api.getClubInfo(id).then(({data}) => {
                setClubInfo(data);
                setLoaded(false);
            });
        }
    }, [loaded, clubInfo, id]);


    const showSecondaryPersonInfo = () => {
        const {name} = clubInfo!;
        const {runners} = clubInfo!;


        return (
            <SecondaryInfo>
                {name !== undefined && (
                    <SecondaryInfoBlock>
                        <SecondaryInfoBlockTitle>Клуб</SecondaryInfoBlockTitle>
                        <SecondaryInfoBlockValue>
                            {name}
                        </SecondaryInfoBlockValue>
                    </SecondaryInfoBlock>
                )}
                {runners !== undefined && (
                    <SecondaryInfoBlock>
                        <SecondaryInfoBlockTitle>Учасників</SecondaryInfoBlockTitle>
                        <SecondaryInfoBlockValue>
                            {runners}
                        </SecondaryInfoBlockValue>
                    </SecondaryInfoBlock>
                )}


            </SecondaryInfo>
        );
    };


    const showUsers = () => {
        if (clubInfo && clubInfo.users.length !== 0) {
            const {users} = clubInfo;

            return (
                <>
                    <SectionTitle>Учасники клубу</SectionTitle>
                    <RaceResults>
                        <RacesWrapper>
                            <Table>
                                <TableHeader>
                                    <TableHeaderBlock>№</TableHeaderBlock>
                                    <TableHeaderBlock
                                    >
                                        Імя
                                    </TableHeaderBlock>
                                    <TableHeaderBlock
                                    >
                                        Кількість тренувань
                                    </TableHeaderBlock>
                                    <TableHeaderBlock
                                    >
                                        Км за тиждень
                                    </TableHeaderBlock>
                                </TableHeader>
                                {loaded ? (
                                    <Loader/>
                                ) : (
                                    <TransitionGroup component={TableBody}>
                            {
                                users
                                    .sort((user1, user2) => (user1.distance < user2.distance) ? 1 : -1)
                                    .map((user, index) => {
                                const {
                                    id,
                                    name,
                                    distance,
                                    activities,
                                    avatar,
                                } = user;
                                const hasAvatar = avatar !== null;
                                return (
                                    <TableRowWrapper key={id}>
                                        <TableRow to={`/users/${id}/info`}>
                                            <TableColumn>{index + 1}</TableColumn>
                                            <TableColumn>
                                                <UserPhotoWrapper hasPhoto={hasAvatar}>
                                                    {avatar ? (
                                                        <UserPhoto src={avatar}/>
                                                    ) : (
                                                        <PhotoPlaceholder/>
                                                    )}
                                                </UserPhotoWrapper>
                                                <span>{name}</span>
                                            </TableColumn>


                                            <TableColumn>{activities} </TableColumn>
                                            <TableColumn>{distance} км </TableColumn>
                                        </TableRow>
                                    </TableRowWrapper>

                            );
                            })}
                                    </TransitionGroup>
                                )}
                            </Table>
                        </RacesWrapper>
                    </RaceResults>
                </>
            );
        }
        return null;
    };



    const Banner = styled.img`
  display: block;
  width: 100%;
  height: 34rem;
  max-width: 100%;
  object-fit: cover;
  background-color: ${colors.white};

  position: relative;

  box-shadow: 0 10px 10px 1px rgba(0, 0, 0, 0.3);

  @media (max-width: ${breakpoints.s}px) {
    height: 20rem;
  }
`;


    const getClubInfo = () => {
        if (clubInfo && !loaded) {
            const {
                club_avatar,
                club_fb,
                club_inst,
                club_tg
            } = clubInfo;


            return (

                <InfoWrapper>
                    <Banner src={club_avatar}/>
                    <MainInfo>
                        <MainInfoLeft>
                            <PhotoAndSocialsWrapper>

                                <SocialsList>
                                    {club_fb && (
                                        <SocialsListItem>
                                            <SocialsListLink target="_blank" href={club_fb}>
                                                <FaFacebook/>
                                            </SocialsListLink>
                                        </SocialsListItem>
                                    )}
                                    {club_inst && (
                                        <SocialsListItem>
                                            <SocialsListLink target="_blank" href={club_inst}>
                                                <FaInstagram/>
                                            </SocialsListLink>
                                        </SocialsListItem>
                                    )}
                                    {club_tg && (
                                        <SocialsListItem>
                                            <SocialsListLink target="_blank" href={club_tg}>
                                                <FaTelegram/>
                                            </SocialsListLink>
                                        </SocialsListItem>
                                    )}
                                </SocialsList>
                            </PhotoAndSocialsWrapper>

                        </MainInfoLeft>

                    </MainInfo>
                    {showSecondaryPersonInfo()}
                </InfoWrapper>
            );
        } else {
            return <Loader/>;
        }
    };

    return (
        <>
            <HeaderBackground/>
            <SectionTitle direction="ltr">
                Клуб
            </SectionTitle>
            <UserInfoWrapper>
                <UserInfoContainer>{getClubInfo()}</UserInfoContainer>
            </UserInfoWrapper>
            {showUsers()}
            <Footer/>
        </>
    );
};
