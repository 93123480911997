import React, {FC} from 'react';
import styled from 'styled-components';

import {Button, ContainerBasedOnPaddings} from '../../../sematic/common';
import backgroundheader from '../../../assets/images/natali.jpg';
import {breakpoints} from '../../../sematic/breakpoints';
import {SectionLRD} from "../../common/section-title";
import {TitleHeader} from "../title/TitleHeader";
import {pages} from "../menu/navigationList";
import {Header} from "../../common/header";
import {NavigationProps} from "../menu/component/NavigationProps";
import { TitleContentSimple } from '../title/TitleContentSimple';

const HeaderWrapper = styled.div<NavigationProps & { isScrolled: boolean }>`
  width: 100vw;
  //height: 100vh;

  position: relative;
  z-index: 1;
  margin-top: -100px;
  
  background: url(${backgroundheader}) no-repeat center;
  background-size: cover;

  @media (max-width: ${breakpoints.m}px) {
    margin-top: 0;
   // background: none;
    height: 100%;
    margin-bottom: 3rem;
  }
`;


const HeaderContainer = styled(ContainerBasedOnPaddings)`
  display: flex;
  align-items: center;

  //height: 100%;
  color: ${props => props.theme.colors.black};

  position: relative;
  z-index: 1;

  @media (max-width: ${breakpoints.s}px) {
   // background: none;

    &:before,
    &:after {
      display: block;
      content: '';

      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }

    &:after {
      z-index: -1;
      background-color: ${props => props.theme.colors.black};
      opacity: 0.5;
      clip-path: polygon(0 0, 100% 0%, 100% 86%, 0 93%);
    }

    &:before {
      background: url(${backgroundheader}) no-repeat;
      background-position: 75%;
      background-size: cover;
      z-index: -2;
      clip-path: polygon(0 0, 100% 0%, 100% 86%, 0 93%);
    }
  }
`;


const ConnectButton = styled(Button)`
  align-self: start;
  color: #fe13a0;
  border: 5px solid #fe13a0; 
  @media (max-width: ${breakpoints.s}px) {
    align-self: center;

    position: absolute;
    bottom: -2rem;
  }
`;

export const HeaderBackground: FC = () => {
    return (
        <>
            <Header activePage={pages.MAIN}/>

        <HeaderWrapper isScrolled={true}>

            <SectionLRD direction="ltr">

                <TitleContentSimple>

                </TitleContentSimple>

            </SectionLRD>
            <HeaderContainer>
                <TitleHeader>
                    <ConnectButton href="https://t.me/+Cgpu6t9-E98yMDc6" target="__blank">
                        Приєднатися
                    </ConnectButton>
                </TitleHeader>
            </HeaderContainer>
        </HeaderWrapper>
        </>
    );
};
