import React, {FC} from 'react';
import styled, {css} from 'styled-components';
import {breakpoints} from "../../../sematic/breakpoints";


interface TitleHeaderProps {
    inOneLine?: boolean;
}

const TitleHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100vw;
  padding: 2rem 0;
  color: ${props => props.theme.colors.lrd};
  font-weight: bold;
  text-transform: uppercase;
  //margin-top: 300px;


  &:before,
  &:after {
    display: block;
    content: '';

    height: 6px;
    width: 100%;
     
    position: relative;
  }

  @media (max-width: ${breakpoints.s}px) {
    display: none;
  }
`;

const TitleContentWrapper = styled.div<TitleHeaderProps>`
  padding: 50rem 4rem;
  text-align: center;
  margin-bottom: 40px;

  @media (min-width: ${breakpoints.xl}px) {
    padding: 60rem 4rem;
  }
  
  ${(props: any) => props.inOneLine &&
    css`
      white-space: nowrap;
    `};
 
  > svg {
    font-size: 6rem;
  }
`;

export const TitleHeader: FC<TitleHeaderProps> = ({
                                                                    children,
                                                                    inOneLine
                                                                }) => (
    <TitleHeaderWrapper>
        <TitleContentWrapper inOneLine={inOneLine} >
            {children}
        </TitleContentWrapper>
    </TitleHeaderWrapper>
);
