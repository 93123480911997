import React, {FC} from 'react';
import styled from 'styled-components';
import {AiOutlineUser} from 'react-icons/ai';

interface UserPhotoProps {
    avatar?: string;
}

const Underlay = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  width: 150%;
 
  transform-origin: 0 0;

  &:before,
  &:after {
    display: block;
    content: '';
    width: 100%;

    
  }

  &::before {
    height: 1rem;
  }

  &::after {
    height: 4.5rem;
    margin-top: 1rem;
  }
`;
const PhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 255px;
  height: 255px;

  overflow: hidden;
`;
const PhotoWrapper = styled.div`
  width: 210px;
  height: 210px;
  overflow: hidden;
  box-shadow: ${({theme}) => theme.boxShadow};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.white};
`;

const Photo = styled.img`
  width: 100%;
  object-fit: cover;
`;
const PhotoPlaceholder = styled(AiOutlineUser)`
  box-shadow: ${({theme}) => theme.colors.black};
  font-size: 10rem;
`;

export const UserPhoto: FC<UserPhotoProps> = ({avatar}) => {
    return (
        <PhotoContainer>
            <Underlay/>
            <PhotoWrapper>
                {avatar !== undefined ? <Photo src={avatar}/> : <PhotoPlaceholder/>}
            </PhotoWrapper>
        </PhotoContainer>
    );
};
