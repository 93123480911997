import React, {FC, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import api from '../../../../axiosApi';
import {Loader} from '../../loader/loader';
import {Footer} from '../../../common/footer';

import {SectionLRD, SectionTitle} from '../../../common/section-title';
import {breakpoints} from '../../../../sematic/breakpoints';
import {Button, ContainerBasedOnPaddings} from '../../../../sematic/common';
import parse from "html-react-parser";
import {RaceInfoData} from "../RaceInfoData";
import {Header} from "../../../common/header";
import {pages} from "../../menu/navigationList";
import {TitleHeader} from "../../title/TitleHeader";
import {NavigationProps, NavigationRaceProps} from "../../menu/component/NavigationProps";
import {TitleContentSimple} from "../../title/TitleContentSimple";
import {HeaderBackground} from "../../headerbg/HeaderBackground";
import backgroundheader from "../../../../assets/images/bg2.jpg";

moment.locale('ru');

//todo рефакторнуть
interface Result {
    distance: string;
    moving_time: string;
}

interface RaceResult extends Result {
    name: string;
    location_country: string;
    start_date_local: string;
}

interface UserInfoBase {
    id: string;
    date: string;
    country: string;
    city: string;
    place: string;
    price: string;
    name: string;
    content: string;
    instagram_tag: string;
    imageUrl: string;
    race_participants: string;
    race_participants1000: RaceResult[];
    race_participants3000: RaceResult[];
    race_participants5000: RaceResult[];

    race_participants7000: RaceResult[];
    race_participants12000: RaceResult[];
    race_participants25000: RaceResult[];

    race_participants10000: RaceResult[];
    race_participants21000: RaceResult[];
    race_participants42000: RaceResult[];
}

type UserInfo = UserInfoBase | undefined;

const UserInfoContainer = styled(ContainerBasedOnPaddings)`
 // margin-top: 5rem;
  margin-bottom: 5rem;
`;
const UserInfoWrapper = styled.main`
  
  flex-grow: 1;
`;
const InfoWrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.black };

  > span:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
const InfoContentWrapper = styled.section`
  display: flex;
  flex-direction: column; 

  > span:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const TitleMetric = styled.h2`
  display: flex;
  align-items: center;

  color: ${props => props.theme.colors.white};
  font-weight: 300;
  margin-bottom: 0;
  font-size: 2rem;
  text-transform: uppercase;
  white-space: nowrap;

  @media (max-width: ${breakpoints.m}px) {
    font-size: 1.6rem;
    text-align: center;
  }
`;



const AboutUsMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a:link {
    background: #fe13a0;
    text-decoration: none;
  }
`;

const RaceResults = styled(ContainerBasedOnPaddings)`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.m}px) {
    max-width: 100%;
  }
`;
const RacesWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
  background-color: #fe13a0;
`;



const MetricsContainer = styled(ContainerBasedOnPaddings)`
  display: flex;
  justify-content: center;

 
`;

const MetricsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  background-color: black;
  padding: 5rem 10rem;
  margin: 5rem 0; 
  position: relative;
  z-index: 1;
  border-left: 5px solid #fe13a0;
  border-right: 5px solid #fe13a0;
  border-top: 5px solid #fe13a0;
 
  width: 100%;

  &:after {
    display: block;
    content: '';

    background-color: ${props => props.theme.colors.black};

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

     
  }

  @media (max-width: ${breakpoints.m}px) {
    margin: 0;

    &:after {
       
    }
  }
`;



const Value = styled.div`
  color: ${props => props.theme.colors.yellow};
  font-weight: bold;
  font-size: 4rem;
  margin: 0 1rem;

  @media (max-width: ${breakpoints.m}px) {
    font-size: 3rem;
    white-space: nowrap;
  }
`;

const Metric = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.yellow};

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media (max-width: ${breakpoints.m}px) {
    flex-flow: column nowrap;
  }
`;

const JoinUsButton = styled(Button)`
  color: ${props => props.theme.colors.black};
  margin-top: 3rem;

  &:after {
    background-color: ${props => props.theme.colors.yellow};
  }

  &:hover {
    color: ${props => props.theme.colors.black} !important;
  }

  @media (max-width: ${breakpoints.m}px) {
    margin-top: 1.5rem;
  }
`;



const HeaderRaceWrapper = styled.div<NavigationRaceProps & { isScrolled: boolean , imageUrl: string}>`
  width: 100vw;
  height: 100vh;

  position: relative;
  z-index: 1;
  margin-top: -100px;
  background: url(${props => props.imageUrl}) no-repeat center;
  background-size: cover;


  @media (max-width: ${breakpoints.m}px) {
    margin-top: 0;
    // background: none;
    height: 100%;
    margin-bottom: 3rem;
  }
`;




export const RaceInfo: FC = () => {
    const [userInfo, setUserInfo] = useState<UserInfo>();
    const [loaded, setLoaded] = useState(true);
    const {id} = useParams();

    useEffect(() => {
        if (loaded) {
            api.getRaceInfo(id).then(({data}) => {
                setUserInfo(data);
                setLoaded(false);
            });
        }
    }, [loaded, userInfo, id]);


    const showRaceResults = () => {
        if (userInfo && userInfo.race_participants.length !== 0) {
            /**
             *
             *     race_participants7000: RaceResult[];
             *     race_participants12000: RaceResult[];
             *     race_participants25000: RaceResult[];
             */
            const {race_participants7000} = userInfo;
            const {race_participants12000} = userInfo;
            const {race_participants25000} = userInfo;

            const {race_participants1000} = userInfo;
            const {race_participants3000} = userInfo;
            const {race_participants5000} = userInfo;
            const {race_participants10000} = userInfo;
            const {race_participants21000} = userInfo;
            const {race_participants42000} = userInfo;

            return (
                <>

                    {race_participants7000.length !== 0 ? (
                        <>
                    <SectionTitle>Дистанція 7000м</SectionTitle>
                    <RaceResults>
                        <RacesWrapper>
                            {race_participants7000.map((raceResult: RaceResult) => (
                                <RaceInfoData raceInfo={raceResult}/>
                            ))}
                        </RacesWrapper>
                    </RaceResults>
                        </>
                        ) : (
                        <span></span>
                    )}
                    {race_participants12000.length !== 0 ? (
                        <>
                    <SectionTitle>Дистанція 12000м</SectionTitle>
                    <RaceResults>
                        <RacesWrapper>
                            {race_participants12000.map((raceResult: RaceResult) => (
                                <RaceInfoData raceInfo={raceResult}/>
                            ))}
                        </RacesWrapper>
                    </RaceResults>
                        </>
                        ) : (
                        <span></span>
                    )}

                    {race_participants25000.length !== 0 ? (
                        <>
                    <SectionTitle>Дистанція 25000м</SectionTitle>
                    <RaceResults>
                        <RacesWrapper>
                            {race_participants25000.map((raceResult: RaceResult) => (
                                <RaceInfoData raceInfo={raceResult}/>
                            ))}
                        </RacesWrapper>
                    </RaceResults>
                        </>
                        ) : (
                        <span></span>
                    )}




                    {race_participants1000.length !== 0 ? (
                        <>
                    <SectionTitle>Дистанція 1000м</SectionTitle>
                    <RaceResults>
                        <RacesWrapper>
                            {race_participants1000.map((raceResult: RaceResult) => (
                                <RaceInfoData raceInfo={raceResult}/>
                            ))}
                        </RacesWrapper>
                    </RaceResults>
                        </>
                        ) : (
                        <span></span>
                    )}
                    {race_participants3000.length !== 0 ? (
                        <>
                            <SectionTitle>Дистанція 3000м</SectionTitle>
                    <RaceResults>
                        <RacesWrapper>
                            {race_participants3000.map((raceResult: RaceResult) => (
                                <RaceInfoData raceInfo={raceResult}/>
                            ))}
                        </RacesWrapper>
                    </RaceResults>
                        </>
                    ) : (
                        <span></span>
                    )}
                    {race_participants5000.length !== 0 ? (
                        <>
                            <SectionTitle>Дистанція 5000м</SectionTitle>
                    <RaceResults>
                        <RacesWrapper>
                            {race_participants5000.map((raceResult: RaceResult) => (
                                <RaceInfoData raceInfo={raceResult}/>
                            ))}
                        </RacesWrapper>
                    </RaceResults>
                            </>
                    ) : (
                        <span></span>
                    )}
                    {race_participants10000.length !== 0 ? (
                        <>
                            <SectionTitle>Дистанція 10000м</SectionTitle>
                    <RaceResults>
                        <RacesWrapper>
                            {race_participants10000.map((raceResult: RaceResult) => (
                                <RaceInfoData raceInfo={raceResult}/>
                            ))}
                        </RacesWrapper>
                    </RaceResults>         </>
                    ) : (
                        <span></span>
                    )}
                    {race_participants21000.length !== 0 ? (
                        <>
                            <SectionTitle>Дистанція 21000м</SectionTitle>
                    <RaceResults>
                        <RacesWrapper>
                            {race_participants21000.map((raceResult: RaceResult) => (
                                <RaceInfoData raceInfo={raceResult}/>
                            ))}
                        </RacesWrapper>
                    </RaceResults></>
                    ) : (
                        <span></span>
                    )}
                    {race_participants42000.length !== 0 ? (
                        <>
                            <SectionTitle>Дистанція 42000м</SectionTitle>
                    <RaceResults>
                        <RacesWrapper>
                            {race_participants42000.map((raceResult: RaceResult) => (
                                <RaceInfoData raceInfo={raceResult}/>
                            ))}
                        </RacesWrapper>
                    </RaceResults></>
                    ) : (
                        <span></span>
                    )}
                </>
            );
        }
        return null;
    };

    const getRaceInfo = () => {
        if (userInfo && !loaded) {
            const {
                id,
                content,
                race_participants,
                imageUrl,
            } = userInfo;

            return (
                <>
                    <Header activePage={pages.MAIN}/>

                    <HeaderWrapper isScrolled={true} imageUrl={imageUrl}>

                        <SectionLRD direction="ltr">

                            <TitleContentSimple>

                            </TitleContentSimple>

                        </SectionLRD>

                    </HeaderWrapper>


                    <UserInfoWrapper>
                    <InfoContentWrapper>
                        <SectionTitle>Інформація про івент</SectionTitle>
                        <UserInfoContainer>
                            <AboutUsMain>

                                {!loaded ? parse(content) : <Loader/>}

                            </AboutUsMain>
                        </UserInfoContainer>

                    </InfoContentWrapper>
                    <InfoWrapper>
                        <MetricsContainer>
                            <MetricsWrapper>
                                <Metric>
                                    <TitleMetric>
                                        Вже зареестровано:
                                        <Value>
                                            {!loaded ? race_participants : <Loader/>}
                                        </Value>
                                    </TitleMetric>
                                </Metric>

                                    <JoinUsButton href={`https://app.longrun.dp.ua/user/race/${id}/register`}>
                                        Реестрація
                                    </JoinUsButton>

                                    <JoinUsButton href={`/race/${id}/results`}>
                                        Результати
                                    </JoinUsButton>

                            </MetricsWrapper>
                        </MetricsContainer>

                    </InfoWrapper>
                    </UserInfoWrapper>
                    {showRaceResults()}

                </>
            );
        }
        return <Loader/>;
    };
    /**
     *    <Header activePage={pages.MAIN}/>
     *     <HeaderRaceWrapper isScrolled={true} imageUrl={`${getImage()}`}/>
     */
    return (

<>

    { getRaceInfo()}
    <Footer/>
</>

    );
};


const HeaderWrapper = styled.div<NavigationRaceProps & { isScrolled: boolean, imageUrl: string }>`
  width: 100vw;
  height: 100vh;

  position: relative;
  z-index: 1;
  margin-top: -100px;
  
  background: url(${props => props.imageUrl}) no-repeat center;
  background-size: cover;

  @media (max-width: ${breakpoints.m}px) {
    margin-top: 0;
   // background: none;
    height: 100%;
    margin-bottom: 3rem;
  }
`;

