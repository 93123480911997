import React, {FC, useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {FaInstagram, FaPhone, FaTelegramPlane} from 'react-icons/fa';
import {IoIosMail} from 'react-icons/io';
import {useThrottledFn, useWindowResize} from 'beautiful-react-hooks';

import {ContainerBasedOnPaddings} from '../../sematic/common';
import {breakpoints} from '../../sematic/breakpoints';

import {Logo} from './logo';
import {DebouncedFunc} from "beautiful-react-hooks/shared/types";
import {BiTerminal} from "react-icons/all";

const FooterWrapper = styled.footer`
  
  position: relative;
  padding: 1rem 0;

  @media (max-width: ${breakpoints.s}px) {
    padding: 0;
    padding-bottom: 2rem;
    background-color: ${props => props.theme.colors.black};
  }
`;

const Link = styled.a`
  display: block;
  transition: ${props => props.theme.transition};
  border-radius: 50%;
  font-size: 3rem;

  color: ${props => props.theme.colors.black};

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.red};
  }

  @media (max-width: ${breakpoints.s}px) {
    color: ${props => props.theme.colors.yellow};
  }
`;

const Title = styled.h2`
  color: ${props => props.theme.colors.black};
  margin: 0 0 1rem;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: bold;

  @media (max-width: ${breakpoints.s}px) {
    display: none;
  }
`;

const Column = styled.div<{ uiCentered?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props: any) =>
    props?.uiCentered &&
    css`
      align-items: center;
    `};

  @media (max-width: ${breakpoints.s}px) {
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
`;

const ListItem = styled.li`
  &:not(:last-child) {
    margin-right: 1.5rem;
  }
`;

const List = styled.ul`
  display: flex;
  justify-content: center;
`;

const NavList = styled(List)`
  flex-flow: column nowrap;

  @media (max-width: ${breakpoints.s}px) {
    align-items: center;

    > li:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

const FooterContainer = styled(ContainerBasedOnPaddings)`
  display: flex;
  justify-content: space-between;

  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (max-width: ${breakpoints.s}px) {
    flex-flow: column nowrap;
    justify-content: center;
  }
`;

const contactsCss = css`
  display: flex;
  align-items: center;
  font-weight: 400;

  font-size: 1.6rem;
  color: ${props => props.theme.colors.black};

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.red};
  }

  > svg {
    font-size: 2rem;
    margin-right: 1rem;
  }

  @media (max-width: ${breakpoints.s}px) {
    color: ${props => props.theme.colors.yellow};
    font-size: 2rem;
  }
`;

const Mail = styled.a`
  ${contactsCss.toString()}
`;
const Telephone = styled.a`
  ${contactsCss.toString()}
`;

export const Footer: FC = () => {
    const [logoMode, setLogoMode] = useState<'desktop' | 'mobile'>('desktop');
    const [width, setWidth] = useState(window.innerWidth);


    const onWindowResizeHandler: DebouncedFunc<() => void> = useThrottledFn(() =>
        setWidth(window.innerWidth)
    );

    useWindowResize(onWindowResizeHandler);

    useEffect(() => {
        width < breakpoints.s ? setLogoMode('mobile') : setLogoMode('desktop');
        return () => onWindowResizeHandler.cancel();
    }, [onWindowResizeHandler, width]);

    return (
        <FooterWrapper>
            <FooterContainer>
                <Column>

                    <Logo isScrolled={logoMode === 'desktop' ? true : false}/>
                </Column>
                <Column>

                    <NavList>
                        <ListItem>
                            <Mail href="mailto:admin@longrun.dp.ua">
                                <IoIosMail/> admin@longrun.dp.ua
                            </Mail>
                        </ListItem>
                        <ListItem>
                            <Telephone href="tel:+380971374758">
                                <FaPhone/> +380971374758
                            </Telephone>
                        </ListItem>
                    </NavList>
                </Column>

                <Column>

                    <NavList>
                        <ListItem>
                            <a href="/wfp/terms">
                                Правила і умови
                            </a>
                        </ListItem>

                    </NavList>
                </Column>
            </FooterContainer>
        </FooterWrapper>
    );
};
