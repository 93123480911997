import React, {Component} from 'react';
import '../../../assets/test.css';
import styled from "styled-components";
import {breakpoints} from "../../../sematic/breakpoints";



const CardFlip = styled.div`
  margin: 0 1rem;
 
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
`;
const CardNumber = styled.span`
  display: block;
  position: relative;
  min-width: 11rem;
  padding: 1.5rem .8rem;
  margin-bottom: 1.5rem;
  font-size: 16rem;
  background-color: hsl(236, 21%, 26%);
  color: hsl(324deg 99% 54%);
  letter-spacing: normal;
  border-radius: .5rem;
  box-shadow: 0 .65rem hsl(234, 17%, 12%);
  overflow: hidden; 

  @media (max-width: ${breakpoints.s}px) {
    min-width: auto;
    padding: 1rem .5rem;
    font-size: 8rem;
    box-shadow: 0 .4rem hsl(234, 17%, 12%);
  }
`;

const CardP = styled.p`
    color: #fe16a1;
    font-size: 2.5rem;
    
      @media (max-width: ${breakpoints.s}px) {
    font-size: .6rem
  }
`;
const CardnumberTop = styled.span`
  position: absolute;
  width: 100%;
  height: 50%;
  inset: 0;
  background-color: rgba(0,0,0,.2);
  transition: .1s;
  transform: rotateY(1turn);
  
}
&:before, &:after {

  content: '';
  position: absolute;
  right: -.5rem;
  bottom: -.5rem;
  background-color: hsl(324deg 99% 54%);
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
}
&:after {
  left: -.5rem;
    
      @media (max-width: ${breakpoints.s}px) {
        min-width: auto; 
        font-size: 2rem; 
  }
`;

const  Flipper = styled.div`
position:relative;
transform-style: preserve-3d;
height:100%;
z-index:2;
`;

const Timer = (props: {
    count: any | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
    time: any | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
 }

) => {


    return(
        <CardFlip>
         <CardNumber >
             <CardnumberTop/>
             {props.count}
         </CardNumber>
            <CardP>{props.time}</CardP>
        </CardFlip>
    );
}

export default Timer;