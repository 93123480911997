import React, {FC, useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {AiOutlineUser} from 'react-icons/ai';
import {Link} from 'react-router-dom';

import api from '../../../axiosApi';
import {breakpoints} from '../../../sematic/breakpoints';
import {ContainerBasedOnPaddings} from "../../../sematic/common";
import {TopUsers as TopUser} from "./component/TopUsers";
import {SectionTitle, SectionTopTitle} from "../../common/section-title";
import {Loader} from "../loader/loader";

const TopUsersWrapper = styled.section``;

const TopUsersContainer = styled(ContainerBasedOnPaddings)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    padding: 0;
    max-width: 100%;
  }
`;

const ColWrap = styled(ContainerBasedOnPaddings)`
  column-count: 2;

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    column-count: 1;
    padding: 0;
    max-width: 100%;
  }
`;

const TopUsersList = styled.ul`
  width: 100%;
`;

const UserInfoWrapper = styled.li`
  position: relative;
  justify-content: flex-end;
  &::after {
    display: block;
    content: '';

    position: absolute;
    left: 0;
    top: calc(50%);
    z-index: -1;

    width: 100%;
    height: 5rem;

    transform: translate3d(0px, -50%, 0px) skewX(0deg);
    background-color: #fe13a0;

    ${(props: any) => props.theme && css`
      transition: ${props?.theme.transition};
    `};

    @media screen and (max-width: ${`${breakpoints.s}px`}) {
     // transform: translate3d(0, -50%, 0);
    }
  }

  @media screen and (max-width: ${`${breakpoints.xs}px`}) {
    & .index,
    & .team {
      display: none;
    }
  }

  &:hover {
    &::after {
      transform: translate3d(0, -50%, 0) skewX(0deg);
      opacity: 0.8;

      ${(props: any) => props.theme &&
    css`
                box-shadow: ${props?.theme.boxShadow};
              `}
    }
  }

  &:nth-child(1)::after {
    background-color: #0057B8;
  }

  &:nth-child(2)::after {
    background-color: #FFD700;
  }
 

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const UserInfo = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  font-weight: bold;
  font-size: 2rem;
  cursor: pointer;

  & > .wrapper {
    display: flex;
    align-items: center;

    > .index {
      margin-right: 2rem;
      width: 2rem;
    }

    & > span {
      margin-right: 2rem;
    }
  }
`;

const ViewAllMembersButton = styled(Link)`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1.5rem 4rem;

  color: ${({theme: {colors}}) => colors.white};

  transition: ${({theme}) => theme.transition};
  position: relative;
  z-index: 2;
  cursor: pointer;

  font-size: 1.6rem;

  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  //transform: skewY(-4deg);

  &:after {
    display: block;
    content: '';

    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: ${({theme: {colors}}) => colors.lrd};

    transition: ${({theme}) => theme.transition};
  }

  &:hover {
    color: ${({theme: {colors}}) => colors.white} !important;
    transform: none;
    box-shadow: ${({theme}) => theme.boxShadow};
  }

  &:active {
    transform: scale(0.98);
  }
`;

const PhotoPlaceholder = styled(AiOutlineUser)`
  font-size: 3rem;
  ${(props: any) => props.theme &&
    css`
            color: ${props?.theme.colors.lrd};
          `};
`;

const UserPhoto = styled.img` 
  max-width: 100%;
  object-fit: cover;
  width: 100px;
  height: 100px;
  margin-right: 2rem;
  border-radius: 50%;
`;


const PhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; 
  overflow: hidden;
`;

const UserPhotoWrapper = styled.div<{ hasPhoto: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center; 
  margin-right: 2rem; 
  overflow: hidden;

  ${(props: any) => props?.theme &&
    css`
      box-shadow: ${props?.theme.boxShadow};
    `};

  ${(props: any) => props?.theme &&
    css`
      transition: ${props?.theme.transition};
    `};


  ${(props: any) => props?.hasPhoto &&
    css`
            background: none;
    `};
 //todo hasPhoto
`;

const UserName = styled.span `
  letter-spacing: 0; 
  
`;

export const WeekRating: FC = () => {
    const [topMan, setTopMembersMan] = useState<TopUser[]>([]);
    const [topWoman, setTopMembersWoman] = useState<TopUser[]>([]);
    const [loaded, setLoaded] = useState(true);


    useEffect(() => {
        if (loaded) {
            api.getTopMembersMan().then(({data}) => {
                setTopMembersMan(data);
                setLoaded(false);
            });
            api.getTopMembersWoman().then(({data}) => {
                setTopMembersWoman(data);
                setLoaded(false);
            });
        }
    }, [topMan,topWoman, loaded]);

    return (
        <TopUsersWrapper>
            <SectionTitle direction={'rtl'}>Рейтинг тижня</SectionTitle>
            <ColWrap>

            <TopUsersContainer>
                <SectionTopTitle direction={'rtl'}>Топ 5 Хлопців</SectionTopTitle>
                <TopUsersList>

                    {!loaded ? (
                        topMan.map((topUser: TopUser) => {
                            const {
                                athlete_id,
                                rank,
                                distance,
                                athlete_firstname,
                                athlete_lastname,
                                athlete_picture_url
                            } = topUser;
                            const hasPhoto = athlete_picture_url !== null;


                            return (
                                <>
                                <UserInfoWrapper key={rank}>
                                <UserInfo to={`users/${athlete_id}/info`}>
                                    <UserName>{athlete_firstname} {athlete_lastname}</UserName>
                                <PhotoContainer>


                                            <UserPhoto src={athlete_picture_url}/>



                                </PhotoContainer>
                                    <span>{distance} КМ</span>
                                </UserInfo>
                                </UserInfoWrapper>
                                </>
                            );
                        })
                    ) : (

                        <Loader/>
                    )}
                </TopUsersList>
            </TopUsersContainer>
            <TopUsersContainer>
                <SectionTopTitle direction={'rtl'}>Топ 5 Дівчат</SectionTopTitle>
                <TopUsersList>

                    {!loaded ? (
                        topWoman.map((topUser: TopUser) => {
                            const {
                                athlete_id,
                                rank,
                                distance,
                                athlete_firstname,
                                athlete_lastname,
                                athlete_picture_url
                            } = topUser;
                            const hasPhoto = athlete_picture_url !== null;


                            return (
                                <>
                                    <UserInfoWrapper key={rank}>
                                        <UserInfo to={`users/${athlete_id}/info`}>
                                            <span>{athlete_firstname} {athlete_lastname}</span>
                                            <PhotoContainer>

                                                <UserPhotoWrapper hasPhoto={hasPhoto}>
                                                    {athlete_picture_url ? (
                                                        <UserPhoto src={athlete_picture_url}/>

                                                    ) : (

                                                        <PhotoPlaceholder/>

                                                    )}

                                                </UserPhotoWrapper>

                                            </PhotoContainer>
                                            {distance} КМ
                                        </UserInfo>
                                    </UserInfoWrapper>
                                </>
                            );
                        })
                    ) : (

                        <Loader/>
                    )}
                </TopUsersList>

            </TopUsersContainer>

            </ColWrap><TopUsersContainer> <ViewAllMembersButton to="/users/all">
            Усі бігуни
        </ViewAllMembersButton>
            </TopUsersContainer>

        </TopUsersWrapper>
    );
};
