export const colors = {
    black: '#151515',
    margin: '-100px',
    fullBlack: '#000',
    white: '#fdfffc',
    darkWhite: '#f8f8f8',
    red: '#ffe928',
    orange: '#F2711C',
    olive: '#B5CC18',
    green: '#ffed00',
    teal: '#00B5AD',
    blue: '#5f6bf1',
    violet: '#6435C9',
    purple: '#924c9f',
    pink: '#E03997',
    brown: '#A5673F',
    gray: '#333333',
    grayii: '#757575',
    yellow: '#fe13a0',
    lrd: '#fe13a0',
    transparent: 'transparent',
};

export interface ThemeInterface {
    colors: {
        lrd: string;
        yellow: string;
        green: string;
        blue: string;
        red: string;
        white: string;
        black: string;
        gray: string;
        grayii: string;
    };
    zIndex: {
        dimmer: number;
        defaultPortal: number;
        pageDimmer: number;
        portalModal: number;
        modal: number;
        popover: number;
    };
    boxModel: {
        borderRadius: string;
        buttonBorderRadius: string;
    };
    font: {
        content: string;
        title: string;
        fs24: string;
        fs18: string;
        fs29: string;
    };
    transition: string;
    boxShadow: string;
    lightBoxShadow: string;
}

/**
 * Return theme
 */
export function getTheme(): ThemeInterface {
    return {
        colors: {
            lrd: colors.lrd,
            yellow: colors.yellow,
            green: colors.green,
            blue: colors.blue,
            red: colors.red,
            white: colors.white,
            black: colors.black,
            gray: colors.gray,
            grayii: colors.grayii,
        },
        zIndex: {
            dimmer: 500,
            defaultPortal: 999,
            pageDimmer: 1000,
            portalModal: 1001,
            modal: 1002,
            popover: 2000
        },
        boxModel: {
            borderRadius: '8px',
            buttonBorderRadius: '32px'
        },
        font: {
            content: 'Phonk, Arial, Helvetica, sans-serif',
            title: 'Montserrat, Arial, Helvetica, sans-serif',
            fs24: '2.4rem',
            fs18: '1.8rem',
            fs29: '2.9rem'
        },
        transition: 'all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)',
        boxShadow: '0 0 20px 1px rgba(0, 0, 0, 0.2)',
        lightBoxShadow: '0 0 10px 1px rgba(255, 255, 255, 0.2)'
    };
}

export const uiTheme = getTheme();
