import React from 'react';
import {render} from 'react-dom';

import {uiTheme as theme} from "./sematic/theme";
import {GlobalStyle} from "./sematic/global";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {MainPage} from "./components/module/main/page/main";
import {About} from "./components/module/about/page/about";
import {UserInfo} from "./components/module/users/user-info";
import {RaceInfo} from "./components/module/races/pages/race-info";
import {AllUsers} from "./components/module/users/all-users";
import {Clubs} from "./components/module/club/page/club";
import {Races} from "./components/module/races/pages/races";
import {ClubInfo} from "./components/module/club/page/club-info";
import {RaceResultInfo} from "./components/module/races/pages/race-results";
import {ThemeProvider} from "styled-components";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {RefundCondition, TermsCondition} from "./components/module/wfp/termscondition";

function AuthLink() {
    // 👇️ redirect to external URL
    window.location.replace('https://app.longrun.dp.ua/login');

    return null;
}

Sentry.init({
    dsn: "https://81a099461c6948868c1658bf7748ada7@o4503972144349184.ingest.sentry.io/4503972148609024",
    integrations: [new BrowserTracing()],

    tracesSampleRate: 1.0,
});

const rootElement = document.getElementById('root')
render(
    <ThemeProvider theme={theme}>
    <GlobalStyle/>
    <Router>
        <Routes>
            <Route element={<MainPage/>} path="/"></Route>
            <Route element={<AuthLink/>} path="/auth"> </Route>
            <Route element={<About/>} path="/about"> </Route>
            <Route element={<UserInfo/>} path="/users/:id/info"> </Route>
            <Route element={<RaceInfo/>} path="/race/:id/info"> </Route>
            <Route element={<AllUsers/>} path="/users/all"> </Route>
            <Route element={<Clubs/>} path="/club"> </Route>
            <Route element={<Races/>} path="/events"> </Route>
            <Route element={<ClubInfo/>} path="/club/info/:id"> </Route>
            <Route element={<RaceResultInfo/>} path="/race/:id/results"> </Route>
            <Route element={<TermsCondition/>} path="/wfp/terms"> </Route>
        </Routes>
    </Router>
</ThemeProvider>, rootElement);

