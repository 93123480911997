import React from "react";

export const Hearts = (svg: { width: string | number | undefined; height: string | number | undefined; color: string | undefined; }) => (
    <svg
        width={svg.width}
        height={svg.height}
        viewBox="0 0 1000 1000"
        xmlns="http://www.w3.org/2000/svg"
        fill={svg.color}
    >
        <g>

            <path
                d="M663.7,333.9c-10.9-14-25.3-20.7-34.4-23.8c-13.1-3.7-24.2-1.9-33-0.5c-5.4,0.9-25.3,4.2-44.5,17.9
c-32.5,23.1-40.1,59.2-41.7,67.9c-9.6-20-28.1-57.8-49.1-93.6c-3.8-6.5-11.6-19.6-23.8-35.3c-12.4-15.9-18.6-23.9-28.4-30.7
c-3.4-2.3-36.1-24.7-59.2-12.8c-17.9,9.2-20.9,34.4-23.4,58.7c-4.4,42.8,3.2,78,13.9,116.2c11,39.1,23.6,73.2,40.6,118.1
c18,47.5,36.9,91.7,55.9,132.5c28.5,73.1,51.1,125.6,56.4,130.2c3.7,3.3,7.8,4.1,7.8,4.1c3.2,0.7,6.1,0.2,8.3-0.5
c6.2-7.8,15.2-19.8,24.8-35.3c11.5-18.6,16.7-30.5,29.3-55c12.3-23.8,18.2-33.4,27.5-50.4c13.2-24.2,31.8-60.7,53.7-112.4
c4.1-10.8,9.5-26,15.1-44.5c8.6-28.6,13.7-45.2,16.1-67C678.8,388.1,682.2,357.8,663.7,333.9z M354.1,248.6
c-5.1-3.8-7.8-8.8-6.4-11.9c1.2-2.8,5.4-3.7,10.1-4.6c4.3-0.8,8-0.7,10.5-0.5C363.6,237.3,358.9,243,354.1,248.6z M441.1,301.1
c8.5,13.8,17,27.6,25.5,41.4c-0.6,0.7-1.5,1.6-2.8,2.4c-1.1,0.7-2.2,1.2-3,1.5c-1.9-2-4.7-5.1-7.6-9.1c-4.5-6.2-7-11.6-10.6-19.4
c-1.6-3.5-3.7-8.3-6-14.1C438.1,302.9,439.6,302,441.1,301.1z M389.2,255.8c0.8-1.3,1.8-2.3,2.6-3c2.4,3,4.7,6,7.1,9.1
c-1.8,1.9-3.6,3.7-5.4,5.6c-1-1.7-2.1-3.5-3.1-5.5c-0.8-1.5-1.5-3-2.1-4.4C388.5,257,388.8,256.4,389.2,255.8z M386.2,458.9
c-6.3-15.9-12.4-33.2-17.9-52.1c-8.9-30.2-14.5-58.4-18.1-83.5c13.8,44.6,27.7,89.3,41.5,133.9
C389.9,457.8,388.1,458.4,386.2,458.9z M424.1,361.7c6.9,13.9,13.6,28.7,20.2,44.5c4,9.5,7.6,18.9,11,28c-0.3,2.8-1,5.9-2.5,6.2
c-3.3,0.6-8.3-13.7-12.6-25.2c-4.7-12.6-11.7-30.2-21.3-51.4C420.5,363.1,422.3,362.4,424.1,361.7z M520.1,395.4
c1-4.3,2.2-8,3.4-11.2c0.7-0.1,1.5-0.1,2.4-0.1c1,0,1.9,0.1,2.6,0.2c-1.7,4.2-3.4,9.2-4.6,14.8c-0.6,2.8-1.1,5.4-1.4,7.9
c-1.4-0.3-2.8-0.6-4.2-0.9C518.7,402.9,519.2,399.3,520.1,395.4z M627.2,333.7c1-0.7,2-1.1,2.6-1.3c2.7,1.9,5.6,4.3,8.6,7.2
c1.7,1.7,3.2,3.3,4.5,4.9c-0.6,0.4-1.4,0.9-2.2,1.8c-0.8,0.8-1.3,1.6-1.6,2.2c-1.9-1.7-3.9-3.5-6-5.3c-2.8-2.4-5.6-4.7-8.3-7
C625.2,335.5,626,334.6,627.2,333.7z M663.7,380.3c0-3.8-0.2-7.4-0.4-10.9c0.5-0.2,1-0.5,1.5-0.7c0.3,2.7,0.6,5.5,0.8,8.3
c0.5,7,0.6,13.6,0.4,19.7c-0.9-0.1-1.8-0.2-2.8-0.3C663.5,391.4,663.7,386,663.7,380.3z M411.7,540.2c4.1,5.1,7.2,10.1,9.5,14.4
c0.1,0.4,8.6,21.7,16.8,45.2c0.7,2,2.3,6.7,5.4,15.8c4.8,14.3,11.5,35,19.7,61.7c-1.3,0.4-2.6,0.9-3.9,1.3
c-20.9-51.3-41.8-102.5-62.7-153.8C401,528.5,406.5,533.5,411.7,540.2z M498.4,715c-2.2,0.8-15.7-29.4-25.5-57.9
c-10.7-31.1-18.8-65-16.5-65.7c2.4-0.8,16.1,34.2,24.8,59.6C490.4,678,500.8,714.2,498.4,715z M510.9,766.5c-1-0.8-2.3-2.1-3.6-3.9
c-1.2-1.6-3.7-5.2-3.5-9.3c0.3-6.7,7.7-10.5,8.1-10.7C511.5,750.6,511.2,758.5,510.9,766.5z M514.2,568.3
c-0.6-18.2-1.2-36.5-1.8-54.7c2.6,3.7,9.3,14.3,9.2,29.4C521.4,555.3,516.6,564.3,514.2,568.3z M562.6,584.5
c-0.8-14.2,0.3-25.8,1.5-33.9c2.6-17.1,7.3-29.3,15-49.2c2.6-6.8,8.3-21.5,9.6-21.1c0.4,0.1,0.8,1.6-5.4,28.6
c-4.7,20.7-7.1,31.1-11.5,46.3C569.7,562.9,566.6,572.9,562.6,584.5z M628.7,464.9c3.7-11.3,4.7-14.6,5.6-19.4
c0.9-5,0.9-7.4,1.3-7.4c0.8,0,3.7,11.5,0.2,23.8c-3.1,11-9.9,17.6-12.8,20.2C624.5,477.4,626.5,471.6,628.7,464.9z M629.3,419.7
c0.9-2.6,1.8-5.5,2.6-8.6c0.9-3.5,1.6-6.8,2.1-9.9c1.6,2.2,3.8,5.9,5,11c0.9,3.7,0.9,7,0.8,9.3
C636.3,420.9,632.8,420.3,629.3,419.7z"

            />
            <animate
                attributeName="fill-opacity"
                begin="0.7s"
                dur="1.4s"
                values="0.3;1;0.3"
                calcMode="linear"
                repeatCount="indefinite"
            />
        </g>
    </svg>
);