import React, {FC, useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import {animateScroll} from 'react-scroll';
import {colors} from '../../../sematic/theme';
import {SCROLL_CONFIGURATION} from "../../../constants";
import {breakpoints} from "../../../sematic/breakpoints";
import {NavigationProps} from "./component/NavigationProps";

export enum pages {
    MAIN,
    ALL_USERS,
    ABOUT_US,
    AUTH,
    CLUB,
    EVENTS,
}

const NavigationList = styled.ul<{ isMobileView?: boolean }>`
  display: flex;
  height: 100%;
  ${(props): any =>
          props.isMobileView &&
          css`
            flex-direction: column;

            & > li > a {
              padding: 10px;
              color: ${colors.white};
              font-size: 5.5rem;
              @media (max-width: ${breakpoints.s}px) {
                font-size: 4.0rem;
              }

              &:after {
                display: none;
              }

              &:focus,
              &:hover {
                color: ${colors.yellow};
              }
            }
          `};
`;


const NavigationLink = styled(Link)<NavigationProps & { isActive: boolean }>`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.6rem;
  text-transform: uppercase;
  color: ${props =>
          props.isScrolled ? props.theme.colors.lrd : props.theme.colors.black};
  position: relative;
  transition: ${props => props.theme.transition};

  height: 100%;
  padding: 0 20px;

  cursor: pointer;

  &:after {
    display: block;
    content: '';

    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    background-color: ${props =>
            props.isScrolled ? props.theme.colors.lrd : props.theme.colors.black}; 

    transform: translateY(100%);
    will-change: transform;

    transition: ${props => props.theme.transition};
  }

  &:hover {
    border-bottom: 2px solid ${colors.yellow};

    &:after {
      transform: scaleY(1.3);
    }
  }

  ${(props): any =>
          props.isActive &&
          css`
            color: ${props => props.theme.colors.black};

            &:after {

              background-color: ${props => props.theme.colors.white};
              transform: scaleY(1.3);
            }

            &:hover {
              color: ${(props: any) =>
                      props.isScrolled
                              ? props.theme.colors.black
                              : props.theme.colors.white};

              &:after {
                background-color: ${(props: any) =>
                        props.isScrolled
                                ? props.theme.colors.yellow
                                : props.theme.colors.black};
              }
            }
          }`};
`;

const NavigationItem = styled.li`

`;

interface NavigationListWrapperProps extends NavigationProps {
    className?: string;
}

export const NavigationListWrapper: FC<NavigationListWrapperProps> = ({
                                                                          isScrolled,
                                                                          isMobileView,
                                                                          className,
                                                                          activePage
                                                                      }) => {
    const [hasScrolled, setScrolled] = useState(false);


    useEffect(() => {
        if (!hasScrolled) {
            animateScroll.scrollToTop(SCROLL_CONFIGURATION);
            setScrolled(true);
        }
    }, [hasScrolled]);

    return (
        <NavigationList isMobileView={isMobileView} className={className}>
            <NavigationItem>
                <NavigationLink
                    isActive={activePage === pages.MAIN}
                    isScrolled={isScrolled}

                    to="/"
                >
                    Головна
                </NavigationLink>
            </NavigationItem>
            <NavigationItem>
                <NavigationLink
                    isActive={activePage === pages.EVENTS}
                    to="/events"
                    isScrolled={isScrolled}
                >
                    івенти
                </NavigationLink>
            </NavigationItem>
            <NavigationItem>
                <NavigationLink
                    isActive={activePage === pages.ALL_USERS}
                    to="/users/all"
                    isScrolled={isScrolled}
                >
                    Рейтинг
                </NavigationLink>
            </NavigationItem>
            <NavigationItem>
                <NavigationLink
                    isActive={activePage === pages.CLUB}
                    to="/club"
                    isScrolled={isScrolled}
                >
                    клуби
                </NavigationLink>
            </NavigationItem>
            <NavigationItem>
                <NavigationLink
                    isActive={activePage === pages.ABOUT_US}
                    isScrolled={isScrolled}
                    to="/about"
                >
                    Про нас
                </NavigationLink>
            </NavigationItem>
            <NavigationItem>

                <NavigationLink

                    isActive={activePage === pages.AUTH}
                    isScrolled={isScrolled}
                    to="/auth"
                >
                    Вхід
                </NavigationLink>
            </NavigationItem>
        </NavigationList>
    );
};
