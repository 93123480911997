import axios from 'axios';

 //const url_main = 'https://app.longrun.dp.ua/api';
 const url_new = 'https://app.longrun.dp.ua/api/v2';
//const url = 'http://localhost/api';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAllMembers() {
        return axios.get(`${url_new}/users/all`);
    },
    getTopMembersMan() {
        return axios.get(`${url_new}/users/top/man`);
    },
    getTopMembersWoman() {
        return axios.get(`${url_new}/users/top/woman`);
    },
    getUserInfo(id) {
        return axios.get(`${url_new}/users/${id}/info`);
    },
    getRaces() {
        return axios.get(`${url_new}/race/all`);
    },
    getRaceInfo(id) {
        return axios.get(`${url_new}/race/${id}/info`);
    },
    getRaceResult(id) {
        return axios.get(`${url_new}/race/${id}/results`);
    },
    getClubs() {
        return axios.get(`${url_new}/club/all`);
    },
    getClubInfo(id) {
        return axios.get(`${url_new}/club/${id}/info`);
    },

    getAbout() {
        return axios.get(`${url_new}/about`);
    },
    getInstagramMedia() {
        return axios.get(`${url_new}/instagram`);
    }
};
