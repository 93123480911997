import React, {FC, useEffect, useState} from 'react';
import styled from 'styled-components';
import {compareAsc} from 'date-fns';

import {SectionTitle} from '../../common/section-title';
import {Loader} from '../loader/loader';
import api from '../../../axiosApi';
import {RaceCountdown} from "../countdown/race/RaceCountdown";
import {Race} from "./component/Race";

const SectionWrapper = styled.div`
  overflow: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  z-index: 1; 
`;

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  width: 100%;
  margin-top: 1rem;
`;

export const RaceDetails: FC = () => {
    const [races, setRaces] = useState<Race[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [ComingRace, setComingRace] = useState<Race>();

    const sortRaces = (races: Race[]) =>
        races.sort((raceLeft, raceRight) =>
            compareAsc(new Date(raceLeft.date), new Date(raceRight.date))
        );

    useEffect(() => {
        api
            .getRaces()
            .then(({data}) => {
                const sortedRaces = sortRaces(data);
                setRaces(sortedRaces);
                setLoaded(true);
                setComingRace(sortedRaces[0]);
            })
            .catch(e => console.log(e));
    }, []);

    return (
        <SectionWrapper>
            <Wrapper>
                <SectionTitle direction="ltr">Наші івенти</SectionTitle>
                <Content>
                    {loaded && ComingRace ? (
                        <RaceCountdown
                            startDate={ComingRace.date}
                            banner={ComingRace.imageUrl}
                            url={ComingRace.url}
                            title={ComingRace.name}
                        />
                    ) : (
                        <Loader/>
                    )}
                </Content>
            </Wrapper>

        </SectionWrapper>
    );
};
