import React, {FC} from 'react';
import styled, {css} from 'styled-components';
import {breakpoints} from "../../sematic/breakpoints";
import lrd_serction from "../../assets/lrd_section.svg";

interface RotatefulProps {
    /**
     * Section direction
     */
    direction?: 'rtl' | 'ltr';
}

interface SectionTitleProps extends RotatefulProps {
    inOneLine?: boolean;
}

const SectionTitleWrapper = styled.div<RotatefulProps>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100vw;
  //padding: 2rem 0;
  color: ${props => props.theme.colors.lrd};
  font-weight: bold;
  text-transform: uppercase;
  background-color: #151515;

  &:before,
  &:after {
    display: block;
    content: '';
 
    width: 100%;
    min-height: 150px;
    background-color: #151515;
    background-image: url(${lrd_serction});
    background-position: 50% 52%;
    background-size: auto;
    @media (max-width: ${breakpoints.s}px) { 
      background-size: cover;
    }
    position: relative;
  }
`;

const ResultTitleWrapper = styled.div<RotatefulProps>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100vw;
  //padding: 2rem 0;
  color: ${props => props.theme.colors.lrd};
  font-weight: bold;
  text-transform: uppercase;
  background-color: #151515;
 
`;
const SectionTopTitleWrapper = styled.div<RotatefulProps>`
  display: flex;
  align-items: center;
  position: relative; 
  //padding: 2rem 0;
  color: ${props => props.theme.colors.lrd};
  font-weight: bold;
  text-transform: uppercase;
  background-color: #151515;
 
`;

const SectionLRDTitleWrapper = styled.div<RotatefulProps>`
  //display: flex;
  align-items: center;
  position: relative;
  //width: 100vw;
  padding: 11rem 0;
  color: ${props => props.theme.colors.lrd};
  //color: transparent;
  font-weight: bold;
  text-transform: uppercase; 


  &:before,
  &:after {
    display: block;
    content: '';

    height: 6px;
    width: 100%; 
    position: relative;
  }
 
  @media (max-width: ${breakpoints.s}px) {
    //display: none;
 
    &:before,
    &:after {
      display: block;
      content: '';

      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }

 
  }
`;

const ContentWrapper = styled.div<SectionTitleProps>`
  padding: 2rem 4rem;
  font-size: 4rem;
  
  text-align: center;
  margin-bottom: 40px;
  border-top: 5px solid #fe13a0;  
  border-bottom: 5px solid #fe13a0;
  ${(props: any) => props.inOneLine &&
    css`
      white-space: nowrap;
    `};
 
  > svg {
    
    font-size: 6rem;
  }
`;

const ResultWrapper = styled.div<SectionTitleProps>`
  padding: 2rem 4rem;
  font-size: 4rem;
  width: inherit;
  text-align: center;
  margin-bottom: 40px;
  border-top: 5px solid #fe13a0;  
  border-bottom: 5px solid #fe13a0;
  ${(props: any) => props.inOneLine &&
    css`
      white-space: nowrap;
    `};
 
  > svg {
    
    font-size: 6rem;
  }
`;

const ContentLRDWrapper = styled.div<SectionTitleProps>` 
  font-size: 12rem;
  text-align: center;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 11rem;
  }
  ${(props: any) => props.inOneLine &&
    css`
      white-space: nowrap;
    `};
 
  > svg {
    font-size: 6rem;
  }
`;

export const SectionTitle: FC<SectionTitleProps> = ({
                                                        children,
                                                        inOneLine,
                                                        direction = 'rtl'
                                                    }) => (

    <SectionTitleWrapper direction={direction}>
        <ContentWrapper inOneLine={inOneLine} direction={direction}>
            {children}
        </ContentWrapper>
    </SectionTitleWrapper>

);

export const ResultTitle: FC<SectionTitleProps> = ({
                                                        children,
                                                        inOneLine,
                                                        direction = 'rtl'
                                                    }) => (

    <ResultTitleWrapper direction={direction}>
        <ResultWrapper inOneLine={inOneLine} direction={direction}>
            {children}
        </ResultWrapper>
    </ResultTitleWrapper>

);
export const SectionTopTitle: FC<SectionTitleProps> = ({
                                                           children,
                                                           inOneLine,
                                                           direction = 'rtl'
                                                       }) => (

    <SectionTopTitleWrapper direction={direction}>
        <ContentWrapper inOneLine={inOneLine} direction={direction}>
            {children}
        </ContentWrapper>
    </SectionTopTitleWrapper>

);

export const SectionLRD: FC<SectionTitleProps> = ({
                                                      children,
                                                      inOneLine,
                                                      direction = 'rtl'
                                                  }) => (
    <SectionLRDTitleWrapper direction={direction}>
        <ContentLRDWrapper inOneLine={inOneLine} direction={direction}>
            {children}
        </ContentLRDWrapper>
    </SectionLRDTitleWrapper>
);
