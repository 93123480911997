import React, {FC, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';
import moment from 'moment';

import api from '../../../../axiosApi';
import {Loader} from '../../loader/loader';
import {Footer} from '../../../common/footer';

import {SectionTitle} from '../../../common/section-title';
import {breakpoints} from '../../../../sematic/breakpoints';
import {ContainerBasedOnPaddings} from '../../../../sematic/common';
import {HeaderBackground} from "../../headerbg/HeaderBackground";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {RaceEvents} from "../component/RaceEvents";


const TableRowWrapper = styled.li`
  background-color: ${({theme: {colors}}) => colors.blue};
  border-top: 1px solid ${({theme: {colors}}) => colors.white};
  &.transition-enter {
    opacity: 0;
    transform: translateY(-100x);
    transition: ${({theme: {transition}}) => transition};
    transition-duration: 2s;
  }

  &.transition-enter-active {
    opacity: 1;
    transform: none;
    transition: ${({theme: {transition}}) => transition};
    transition-duration: 2s;
  }


 
`;
const TableRow = styled(Link)`
  display: flex;
  width: 100%;
  padding: 2rem;

  &:hover {
    background-color: ${({theme: {colors}}) => colors.yellow};
  }
`;
const TableColumn = styled.div`
  display: flex;
  align-items: center;

  &:nth-child(1) {
    padding-left: 0.5rem;
    min-width: 160px;
  }
  &:nth-child(2) {
    min-width: 580px;
  }
  &:nth-child(3) {
    min-width: 275px;
  }
  &:nth-child(4) {
    min-width: 155px;
  }

  @media screen and (max-width: ${`${breakpoints.l}px`}) {
    &:nth-child(1) {
      min-width: 60px;
    }
    &:nth-child(2) {
      display: none;

    }
    &:nth-child(3) {
      display: none;

    }
    &:nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: ${`${breakpoints.m}px`}) {
    &:nth-child(1) {
      display: flex;
    }

    &:nth-child(2) {
      flex-grow: 1;
      min-width: 0;
      
    }

    &:nth-child(3) { 
      min-width: 180px;
    }
  }
  @media screen and (max-width: 670px) {
    &:nth-child(3) {
      display: none;
    }
  }

  @media screen and (max-width: ${`${breakpoints.xs}px`}) {
    &:nth-child(4) {
      min-width: 0;
    }
  }

  @media screen and (max-width: 375px) {
    &:nth-child(2) > .fullname {
      max-width: 130px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;


const Table = styled.div`
  margin: 3rem 0;
`;
const TableHeader = styled.ul`
  display: flex;
  margin-bottom: 2rem;

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    justify-content: space-between;
  }
`;
const TableHeaderBlock = styled.li`
  display: flex;
  align-items: center;
  font-weight: bold;
  transition: ${({theme: {transition}}) => transition};
  cursor: pointer;

  &:nth-child(1) {
    min-width: 245px;
  }
  &:nth-child(2) {
    min-width: 500px;
  }
  &:nth-child(3) {
    min-width: 275px;
  }
  &:nth-child(4) {
    min-width: 160px;
  }

  &:hover {
    opacity: 0.5;
  }
 
    
   
  @media screen and (max-width: ${`${breakpoints.l}px`}) {
      &:nth-child(1) {
        min-width: 60px;
      }
      
       &:nth-child(2) {
        flex-grow: 1;
        min-width: 0;
         display: none;

       }
  }
  
  @media screen and (max-width: ${`${breakpoints.m}px`}) {
    &:nth-child(1) {
    //  display: none;
    }
  
    &:nth-child(4) {
      display: none;
 //     min-width: 180px;
    }
  }
  
   @media screen and (max-width: 670px) {
    &:nth-child(3) {
      display: none;
    }
  }
  
   @media screen and (max-width: ${`${breakpoints.xs}px`}) {
   
    &:nth-child(4) {
      min-width: 0;
    }
   }
`;

const TableBody = styled.ul`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${`${breakpoints.xs}px`}) {
    align-items: normal;
  }
`;




const AllMembersWrapper = styled.main`
  flex-grow: 1;
`;
const AllMembersContainer = styled(ContainerBasedOnPaddings)`
  @media screen and (max-width: ${`${breakpoints.l}px`}) {
    max-width: 100%;
  }
`;

export const Races: FC = () => {
    const [races, setRaces] = useState<RaceEvents[]>([]);

    const [loaded, setLoaded] = useState(true);

    useEffect(() => {
        if (loaded) {
            api.getRaces().then(({data}) => {
                setRaces(data);
                setLoaded(false);
            });
        }
    });

    return (
        <>
            <HeaderBackground/>
            <AllMembersWrapper>
                <SectionTitle>Архів івентів</SectionTitle>
                <AllMembersContainer>
                    <Table>
                        <TableHeader>
                            <TableHeaderBlock>Назва</TableHeaderBlock>
                            <TableHeaderBlock> КОЛИ </TableHeaderBlock>
                            <TableHeaderBlock> кількість учасників </TableHeaderBlock>
                            <TableHeaderBlock> дистанціі </TableHeaderBlock>
                        </TableHeader>
                        {loaded ? (
                            <Loader/>
                        ) : (
                            <TransitionGroup component={TableBody}>

                                {races.map((race, index) => {
                                    const {
                                        id,
                                        name,
                                        url,
                                        type,
                                        date,
                                        numberOfParticipants
                                    } = race;
                                    const timeFormat = 'MM DD YYYY, h:mm';
                                    const parsedDate = moment(date).format(timeFormat);
                                    return (
                                        <CSSTransition
                                            key={id}
                                            appear={false}
                                            enter={true}
                                            exit={false}
                                            timeout={1000}
                                        >
                                            <TableRowWrapper key={id}>
                                                <TableRow to={`${url}`}>
                                                    <TableColumn>{name}</TableColumn>
                                                    <TableColumn> {parsedDate} </TableColumn>
                                                    <TableColumn>{numberOfParticipants} </TableColumn>
                                                    <TableColumn>{type} </TableColumn>
                                                </TableRow>
                                            </TableRowWrapper>
                                        </CSSTransition>
                                    );
                                })}
                            </TransitionGroup>
                        )}
                    </Table>
                </AllMembersContainer>
            </AllMembersWrapper>
            <Footer/>
        </>
    );
};
