import React, {FC} from 'react';
import styled, {css} from 'styled-components';
import {NavigationListWrapper} from './navigationList';
import {MobileNavigationProps} from "./component/MobileNavigationProps";

const MobileNavigationWrapper = styled.div<{ isBurgerOpen: boolean }>` 
  display: flex;   
  position: fixed; 
  transform: none; 
  transition: all 0.2s cubic-bezier(0.645,0.045,0.355,1);
  width: 100%;
  z-index: 1000;  
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  max-height: 100vh;
  padding-top: 112px;
  padding-right: 40px;
  padding-left: 40px; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center;

  background-color: ${props => props.theme.colors.black };
  
  ${(props: any) => props?.isBurgerOpen && css` 
   transform: translateX(100%);
 `};
`;

const MobileNavList = styled(NavigationListWrapper)`
  height: auto; 
 
`;



export const MobileNavigation: FC<MobileNavigationProps> = ({
                                                                isBurgerOpen,
                                                                activePage,
                                                                closeBurger
                                                            }) => {
    const onClickHandler = () => closeBurger();

    return (
        <MobileNavigationWrapper
            isBurgerOpen={!isBurgerOpen}
            onClick={onClickHandler}
        >
            <MobileNavList activePage={activePage} isMobileView={true}/>

        </MobileNavigationWrapper>
    );
};
