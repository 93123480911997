import React, {FC} from 'react';
import styled, {css} from 'styled-components';
import {breakpoints} from '../../sematic/breakpoints';
import {animateScroll} from 'react-scroll';
import {SCROLL_CONFIGURATION} from "../../constants";
import {ReactComponent as LongRunLogo} from "../../assets/LrdLogo.svg"

interface LogoProps {
    /**
     * True if user scrolled page
     */
    isScrolled: boolean;
    /**
     * Hide text
     */
    hideText?: boolean;
}

const LogoWrapper = styled.a<LogoProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  > svg {
    height: 100px;

    .filler {
      fill: ${props =>
    props.isScrolled
        ? props.theme.colors.yellow
        : props.theme.colors.black};
        }

    ${(props): any =>
    props.hideText &&
    css`
      .lrd,
      .runners {
        display: none;
      }
    `};

    @media (max-width: ${breakpoints.s}px) {
      height: 100px;
    }
  }
`;

export const Logo: FC<LogoProps> = ({isScrolled, hideText}) => {
    const onClickHandler = () => animateScroll.scrollToTop(SCROLL_CONFIGURATION);

    return (
        <LogoWrapper isScrolled={isScrolled} hideText={hideText} onClick={onClickHandler}>
            <LongRunLogo></LongRunLogo>
        </LogoWrapper>
    );
};

LogoWrapper.defaultProps = {
    hideText: false
};
