
import styled from 'styled-components';
import { ContainerBasedOnPaddings } from '../../../sematic/common';
import {SectionTitle} from "../../common/section-title";
import {breakpoints} from "../../../sematic/breakpoints";
import {FC} from "react";
import {HeaderBackground} from "../headerbg/HeaderBackground";
import {Footer} from "../../common/footer";


const AboutContent = styled.main`
  flex-grow: 1;
  margin-top: 2rem;
`;

const AboutContainer = styled(ContainerBasedOnPaddings)``;

const AboutTitle = styled(SectionTitle)`
  margin-top: 3rem;
`;


const AboutWrapper = styled.section`
  display: flex;
  margin-bottom: 2rem;

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    flex-direction: column;
    margin-bottom: 4rem;
  }
`;

const AboutMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: ${breakpoints.s}px) {
    align-items: center;
  }

  a:link {
    background: #fe13a0;
    text-decoration: none;
  }
  

`;

export const TermsCondition: FC = () => {


    return (
        <>
            <HeaderBackground/>

        <AboutTitle direction="ltr" inOneLine>
    Умови
    </AboutTitle>
    <AboutContent>
    <AboutContainer>
        <AboutWrapper>
            <AboutMain>
                <div>&nbsp;</div>

                <div><strong>Здоров'я</strong></div>
                <div>&nbsp;</div>
                <div>Кожен учасник змагань добровільно бере участь у змаганнях, визнаючи всі ймовірні ризики для власного здоров'я під час і після їх закінчення.</div>

                <div>Кожен учасник змагань бере на себе особисту відповідальність за свій фізичний стан і здатність завершити обрану дистанцію змагань.</div>

                <div>Усі зареєстровані учасники самостійно несуть відповідальність за власне життя та здоров’я, щодо проведення змагання та підписують відповідну заяву.</div>

                <div>Організатор Змагання не несе відповідальності за фізичні травми, ушкодження, погіршення фізичного стану, які учасник Змагання зазнав під час проведення Змагання;</div>

                <div>Організатор Змагання не несе відповідальності за погіршення фізичного стану учасника Змагання після проведення Змагання;</div>

                <div>Організатор Змагання не несе відповідальності за будь-які збитки (втрату або пошкодження особистих речей), які учасник Змагання поніс під час проведення Змагання;</div>

                <div>Організатор Змагання не несе відповідальності за будь-які збитки, пошкодження або іншу шкоду, завдану під час Змагання учасниками або глядачами Змагання.</div>

                <div>&nbsp;</div>

                <div><strong>Реєстрація на Змагання</strong></div>
                <div>&nbsp;</div>
                <div>Учасники допускаються до змагань за умови належної і своєчасної реєстрації на змагання.</div>

                <div>Реєстрація можлива в режимі on-line на сайті <a href="/events">LongRunDnipro</a>&nbsp;</div>

                <div>або до завершення ліміту учасників на кожну окрему дистанцію.</div>

                <div>Реєстрація в день видачі стартових пакетів проводитись не буде!!!</div>

                <div>Організатор змагань має право&nbsp;призупинити або повністю закрити реєстрацію без попередження в разі досягнення ліміту учасників.</div>

                <div>Учасник вважається зареєстрованим, якщо він заповнив заявку та сплатив стартовий внесок і відобразився в стартових протоколах.</div>

                <div>Реєстраційний внесок, сплачений учасником змагань, не підлягає поверненню ні за яких обставин, наприклад: якщо учасник змагань не може взяти участь у змаганнях, завершити обрану дистанцію, не вкладеться у встановлений ліміт часу, а так само, якщо учасник на надав необхідну медичну довідку, якщо</div>

                <div>він дискваліфікований або змагання скасовані через форс-мажорні обставини і т.д.</div>

                <div>Реєстрація учасника анулюється, якщо під час реєстрації ним були надані неточні та/або помилкові данні. В разі анулювання реєстрації грошові кошти не повертаються.</div>

                <div>Стартовий внесок за реєстрацію, якщо такий був сплачений, не повертається. Перереєстрація на іншого учасника або зміна дистанції – 100 грн. з доплатою різниці вартості реєстрації на момент зміни.</div>

                <div>&nbsp;</div>

                <div><strong>Стартові внески</strong></div>
                <div>&nbsp;</div>
                <div>Оплата стартового внеску учасниками Змагання здійснюється електронним платежем за допомогою системи WayForPay на реєстраційній системі https://longrun.dp.ua</div>

                <div>&nbsp;</div>

                <div><strong>Повернення стартового внеску</strong></div>
                <div>&nbsp;</div>
                <div>Повернення грошових коштів, сплачених у рахунок реєстраційного внеску, не проводиться згідно з Договором публічної оферти, який приймається учасником&nbsp; при реєстрації на захід.</div>

                <div>&nbsp;</div>

                <div><strong>Особисті дані</strong></div>
                <div>&nbsp;</div>
                <div>Кожен учасник змагань згоден на використання його особистих даних, фото -, відео - або аудіо матеріалів з його участю і без узгодження з ним для реклами заходу без будь-якої компенсації з боку організатора змагань.</div>

                <div>Всі фото- та відеоматеріали змагань, доступні організатору змагань, можуть бути використані організатором змагань як допоміжні документи, що підтверджують точність результатів змагань і випадки порушення правил змагань.</div>
                <div>&nbsp;</div>
                <div><strong>З питань роботи сайту</strong></div>
                <div>&nbsp;</div>
                <div>Дєлов Віталій Вікторович </div>
                <div>delovvitalik@gmail.com</div>
                <div>+380971374758</div>



            </AboutMain>
    </AboutWrapper>
    </AboutContainer>
    </AboutContent>
    <Footer/>
    </>
);
};
export const RefundCondition: FC = () => {


    return (
        <>
            <HeaderBackground/>

        <AboutTitle direction="ltr" inOneLine>
    Про нас
    </AboutTitle>
    <AboutContent>
    <AboutContainer>
        <AboutWrapper>
            <AboutMain>
                Стартові внески
                Оплата стартового внеску учасниками Змагання здійснюється електронним платежем за допомогою системи WayForPay на реєстраційній системі https://vseprobegi.org

                Повернення стартового внеску
                Повернення грошових коштів, сплачених у рахунок реєстраційного внеску, не проводиться згідно з Договором публічної оферти, який приймається учасником  при реєстрації на захід.

                Особисті дані
                Кожен учасник змагань згоден на використання його особистих даних, фото -, відео - або аудіо матеріалів з його участю і без узгодження з ним для реклами заходу без будь-якої компенсації з боку організатора змагань.
                Всі фото- та відеоматеріали змагань, доступні організатору змагань, можуть бути використані організатором змагань як допоміжні документи, що підтверджують точність результатів змагань і випадки порушення правил змагань.
    </AboutMain>
    </AboutWrapper>
    </AboutContainer>
    </AboutContent>
    <Footer/>
    </>
);
};
