import React, {FC, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import styled, {css} from 'styled-components';
import moment from 'moment';

import api from '../../../../axiosApi';
import {Footer} from '../../../common/footer';

import {ResultTitle, SectionLRD, SectionTitle} from '../../../common/section-title';
import {breakpoints} from '../../../../sematic/breakpoints';
import {HeaderBackground} from "../../headerbg/HeaderBackground";
import {RaceResults} from '../component/RaceResults';
import {Loader} from "../../loader/loader";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {AiOutlineUser} from "react-icons/ai";
import {ContainerBasedOnPaddings} from "../../../../sematic/common";
import {Header} from "../../../common/header";
import {pages} from "../../menu/navigationList";
import {TitleContentSimple} from "../../title/TitleContentSimple";
import {NavigationRaceProps} from "../../menu/component/NavigationProps";

moment.locale('ru');



const Table = styled.div`
  margin: 3rem 0;
`;
const TableHeader = styled.ul`
  display: flex;
  margin-bottom: 2rem;

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    justify-content: space-between;
  }
`;
const TableHeaderBlock = styled.li`
  display: flex;
  align-items: center;
  font-weight: bold;
  transition: ${({theme: {transition}}) => transition};
  cursor: pointer;

  &:nth-child(1) {
    min-width: 245px;
  }
  &:nth-child(2) {
    min-width: 500px;
  }
  &:nth-child(3) {
    min-width: 275px;
  }
  &:nth-child(4) {
    min-width: 160px;
  }

  &:hover {
    opacity: 0.5;
  }

  ${(props: any) => props?.sortOrder &&
    css`
      & > svg {
        margin-left: 1rem;
        transition: ${props?.theme.transition};
        transform: rotateZ(${props?.sortOrder === 'desc' ? 'none' : '180deg'});
      }
      `};
    
   
  @media screen and (max-width: ${`${breakpoints.l}px`}) {
      &:nth-child(1) {
        min-width: 60px;
      }
      
       &:nth-child(2) {
        flex-grow: 1;
        min-width: 0;
      }
  }
  
  @media screen and (max-width: ${`${breakpoints.m}px`}) {
    &:nth-child(1) {
      display: none;
    }
  
    &:nth-child(3) {
      min-width: 180px;
    }
  }
  
   @media screen and (max-width: 670px) {
    &:nth-child(3) {
      display: none;
    }
  }
  
   @media screen and (max-width: ${`${breakpoints.xs}px`}) {
   
    &:nth-child(4) {
      min-width: 0;
    }
   }
`;

const TableBody = styled.ul`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${`${breakpoints.xs}px`}) {
    align-items: normal;
  }
`;


const TableRowWrapper = styled.li`
  &.transition-enter {
    opacity: 0;
    transform: translateY(-100x);
    transition: ${({theme: {transition}}) => transition};
    transition-duration: 2s;
  }

  &.transition-enter-active {
    opacity: 1;
    transform: none;
    transition: ${({theme: {transition}}) => transition};
    transition-duration: 2s;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({theme: {colors}}) => colors.lrd};
  }
`;
const TableRow = styled(Link)`
  display: flex;
  width: 100%;
  padding: 0.5rem;

  &:hover {
    background-color: ${({theme: {colors}}) => colors.yellow};
  }
`;
const TableColumn = styled.div`
  display: flex;
  align-items: center;

  &:nth-child(1) {
    padding-left: 0.5rem;
    min-width: 160px;
  }
  &:nth-child(2) {
    min-width: 580px;
  }
  &:nth-child(3) {
    min-width: 275px;
  }
  &:nth-child(4) {
    min-width: 155px;
  }

  @media screen and (max-width: ${`${breakpoints.l}px`}) {
    &:nth-child(1) {
      min-width: 60px;
    }
    &:nth-child(2) {
      flex-grow: 1;
      min-width: 0;
    }
  }

  @media screen and (max-width: ${`${breakpoints.m}px`}) {
    &:nth-child(1) {
      display: none;
    }

    &:nth-child(2) {
      flex-grow: 1;
      min-width: 0;
    }

    &:nth-child(3) {
      min-width: 180px;
    }
  }
  @media screen and (max-width: 670px) {
    &:nth-child(3) {
      display: none;
    }
  }

  @media screen and (max-width: ${`${breakpoints.xs}px`}) {
    &:nth-child(4) {
      min-width: 0;
    }
  }

  @media screen and (max-width: 375px) {
    &:nth-child(2) > .fullname {
      max-width: 130px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;
const PhotoPlaceholder = styled(AiOutlineUser)`
  font-size: 3rem;
 ${(props: any) => props?.theme && css`
     color: ${props?.theme.colors.black};
      `};
`;


const UserPhoto = styled.img`
  width: 100%;
  max-width: 100%;
  object-fit: cover;
`;
const UserPhotoWrapper = styled.div<{ hasPhoto: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-right: 2rem;
  border-radius: 50%;
  overflow: hidden;

  box-shadow: ${({theme: {boxShadow}}) => boxShadow};
  transition: ${({theme: {transition}}) => transition};

  background: ${({hasPhoto, theme: {colors}}) =>
    !hasPhoto ? colors.white : 'none'};

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    width: 4rem;
    height: 4rem;
  }
`;
const AllMembersWrapper = styled.main`
  flex-grow: 1;
`;
const AllMembersContainer = styled(ContainerBasedOnPaddings)`
  @media screen and (max-width: ${`${breakpoints.l}px`}) {
    max-width: 100%;
  }
`;


interface UserInfoBase {
    imageUrl: undefined;
}

type UserInfo = UserInfoBase | undefined;

export const RaceResultInfo: FC = () => {
    const [raceResult, setRaceResult] = useState<RaceResults[]>([]);
    const [userInfo, setUserInfo] = useState<UserInfo>();
    const [loaded, setLoaded] = useState(true);
    const {id} = useParams();

    useEffect(() => {
        if (loaded) {
            api.getRaceResult(id).then(({data}) => {
                setRaceResult(data);
                setLoaded(false);
            });

            api.getRaceInfo(id).then(({data}) => {
                setUserInfo(data);
                setLoaded(false);
            });
        }
    }, [loaded, raceResult, id]);


    const showRaceResults = (FilterSex: string | undefined, RaceType: string | undefined) => {
        if (raceResult && raceResult.filter(raceResult => raceResult.type === RaceType).length !== 0) {
            return (
                <>

                    <SectionTitle> {RaceType} {FilterSex}</SectionTitle>
                    <AllMembersWrapper>
                        <AllMembersContainer>
                            <Table>
                                <TableHeader>
                                    <TableHeaderBlock>№</TableHeaderBlock>
                                    <TableHeaderBlock
                                    >
                                        Імя
                                    </TableHeaderBlock>
                                    <TableHeaderBlock
                                    >
                                        Середній темп
                                    </TableHeaderBlock>
                                    <TableHeaderBlock
                                    >
                                        ЧАС
                                    </TableHeaderBlock>
                                </TableHeader>
                                {loaded ? (
                                    <Loader/>
                                ) : (
                                    <TransitionGroup component={TableBody}>
                                        {raceResult.filter(race => race.sex === FilterSex && race.type === RaceType)
                                            .map((race, index) => {
                                                const {
                                                    id, athlete_id, name, bib, time, type, avatar,
                                                } = race;
                                                const hasAvatar = avatar !== null;
                                                const timeInSeconds = moment(time, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds');
                                                const distanceInKm = type / 1000;
                                                let paceInKm = timeInSeconds / distanceInKm;
                                                let minutesPaceInKm = paceInKm / 60;
                                                let secondsPaceInKm = paceInKm % 60;

                                                const  paceKm = `${Math.floor(minutesPaceInKm)}:${(secondsPaceInKm >= 0 && secondsPaceInKm < 10) ? "0" : ""}${Math.floor(secondsPaceInKm)} хв/км`


                                                return (
                                                    <CSSTransition
                                                        key={athlete_id}
                                                        appear={false}
                                                        enter={true}
                                                        exit={false}
                                                        timeout={1000}
                                                    >

                                                        <TableRowWrapper key={id}>
                                                            <TableRow to={`/users/${id}/info`}>
                                                                <TableColumn>{index + 1}</TableColumn>
                                                                <TableColumn>
                                                                    <UserPhotoWrapper hasPhoto={hasAvatar}>
                                                                        {avatar ? (
                                                                            <UserPhoto src={avatar}/>
                                                                        ) : (
                                                                            <PhotoPlaceholder/>
                                                                        )}
                                                                    </UserPhotoWrapper>
                                                                    <span
                                                                        className="fullname">{name}</span>
                                                                </TableColumn>

                                                                <TableColumn>{paceKm} </TableColumn>
                                                                <TableColumn> {time} </TableColumn>
                                                            </TableRow>
                                                        </TableRowWrapper>
                                                    </CSSTransition>
                                                );
                                            })}
                                    </TransitionGroup>
                                )}
                            </Table>
                        </AllMembersContainer>
                    </AllMembersWrapper>
                    {showRaceAgeResults(FilterSex, RaceType, '18', '22')}
                    {showRaceAgeResults(FilterSex, RaceType, '23', '29')}
                    {showRaceAgeResults(FilterSex, RaceType, '30', '39')}
                    {showRaceAgeResults(FilterSex, RaceType, '40', '49')}
                    {showRaceAgeResults(FilterSex, RaceType, '50', '59')}
                    {showRaceAgeResults(FilterSex, RaceType, '60', '69')}
                </>


            );
        }
        return null;
    };
    const showRaceAgeResults = (FilterSex: string | undefined, RaceType: string | undefined, AgeMin: string | any, AgeMax: string | any) => {
        if (raceResult && raceResult.filter(raceResult => raceResult.type === RaceType && (raceResult.age >= AgeMin && raceResult.age <= AgeMax)).length !== 0) {
            return (
                <>

                    <ResultTitle>Вікова группа: {AgeMin} - {AgeMax} </ResultTitle>
                    <AllMembersWrapper>
                        <AllMembersContainer>
                            <Table>
                                <TableHeader>
                                    <TableHeaderBlock>№</TableHeaderBlock>
                                    <TableHeaderBlock
                                    >
                                        Імя
                                    </TableHeaderBlock>
                                    <TableHeaderBlock
                                    >
                                        Середній темп
                                    </TableHeaderBlock>
                                    <TableHeaderBlock
                                    >
                                        ЧАС
                                    </TableHeaderBlock>
                                </TableHeader>
                                {loaded ? (
                                    <Loader/>
                                ) : (
                                    <TransitionGroup component={TableBody}>
                                        {raceResult.filter(race => race.sex === FilterSex && race.type === RaceType && (race.age >= AgeMin && race.age <= AgeMax))
                                            .map((race, index) => {
                                                const {
                                                    id, athlete_id, name, bib, time, type, avatar,
                                                } = race;
                                                const hasAvatar = avatar !== null;
                                                const timeInSeconds = moment(time, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds');
                                                const distanceInKm = type / 1000;
                                                let paceInKm = timeInSeconds / distanceInKm;
                                                let minutesPaceInKm = paceInKm / 60;
                                                let secondsPaceInKm = paceInKm % 60;

                                                const  paceKm = `${Math.floor(minutesPaceInKm)}:${(secondsPaceInKm >= 0 && secondsPaceInKm < 10) ? "0" : ""}${Math.floor(secondsPaceInKm)} хв/км`


                                                return (
                                                    <CSSTransition
                                                        key={athlete_id}
                                                        appear={false}
                                                        enter={true}
                                                        exit={false}
                                                        timeout={1000}
                                                    >

                                                        <TableRowWrapper key={id}>
                                                            <TableRow to={`/users/${id}/info`}>
                                                                <TableColumn>{index + 1}</TableColumn>
                                                                <TableColumn>
                                                                    <UserPhotoWrapper hasPhoto={hasAvatar}>
                                                                        {avatar ? (
                                                                            <UserPhoto src={avatar}/>
                                                                        ) : (
                                                                            <PhotoPlaceholder/>
                                                                        )}
                                                                    </UserPhotoWrapper>
                                                                    <span
                                                                        className="fullname">{name}</span>
                                                                </TableColumn>

                                                                <TableColumn>{paceKm} </TableColumn>
                                                                <TableColumn> {time} </TableColumn>
                                                            </TableRow>
                                                        </TableRowWrapper>
                                                    </CSSTransition>
                                                );
                                            })}
                                    </TransitionGroup>
                                )}
                            </Table>
                        </AllMembersContainer>
                    </AllMembersWrapper></>

            );
        };
        return null;
    };

    const resultHeader = () => {
        if (userInfo && !loaded) {
            const {
                imageUrl,
            } = userInfo;

            return imageUrl;
        }
    }
//  <HeaderBackground/>
    return (
        <>
            <Header activePage={pages.MAIN}/>
            <HeaderWrapper isScrolled={true} imageUrl={resultHeader()}>
                <SectionLRD direction="ltr">
                    <TitleContentSimple>
                    </TitleContentSimple>
                </SectionLRD>
            </HeaderWrapper>


            <ResultTitle>Результати івенту</ResultTitle>
            {showRaceResults('F', '1000')}
            {showRaceResults('M', '1000')}
            {showRaceResults('F', '3000')}
            {showRaceResults('M', '3000')}
            {showRaceResults('F', '5000')}
            {showRaceResults('M', '5000')}
            {showRaceResults('F', '10000')}
            {showRaceResults('M', '10000')}
            {showRaceResults('F', '21000')}
            {showRaceResults('M', '21000')}


            {showRaceResults('F', '42000')}
            {showRaceResults('M', '42000')}


            {showRaceResults('F', '7000')}
            {showRaceResults('M', '7000')}
            {showRaceResults('F', '12000')}
            {showRaceResults('M', '12000')}
            {showRaceResults('F', '25000')}
            {showRaceResults('M', '25000')}
            <Footer/>
        </>
    );
};


const HeaderWrapper = styled.div<NavigationRaceProps & { isScrolled: boolean, imageUrl: string | undefined }>`
  width: 100vw;
  height: 100vh;

  position: relative;
  z-index: 1;
  margin-top: -100px;
  
  background: url(${props => props.imageUrl}) no-repeat center;
  background-size: cover;

  @media (max-width: ${breakpoints.m}px) {
    margin-top: 0;
   // background: none;
    height: 100%;
    margin-bottom: 3rem;
  }
`;
