import React, {FC, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {FaFacebookF, FaInstagram, FaStrava} from 'react-icons/fa';
import moment from 'moment';

import api from '../../../axiosApi';
import {Loader} from '../loader/loader';
import {Footer} from '../../common/footer';

import {SectionTitle} from '../../common/section-title';
import {UserPhoto} from '../../common/photo';
import {breakpoints} from '../../../sematic/breakpoints';
import {UserLastRun} from './userLastRun';
import {ContainerBasedOnPaddings} from '../../../sematic/common';
import {HeaderBackground} from "../headerbg/HeaderBackground";

moment.locale('ru');

interface Result {
    distance: string;
    moving_time: string;
}

interface RaceResult extends Result {
    name: string;
    location_country: string;
    start_date_local: string;
}

interface UserInfoBase {

    athlete_firstname: string;
    athlete_lastname: string;
    athlete_picture_url: string;
    birthday: string;
    city: string;
    //startRunning: string;
    instagram: string;
    facebook: string;
    strava: string;
    club: string;
    club_id: string;
    kilometersOverall: number;
    kilometersThisMonth: number;
    movingTimeThisMonth: number;
    avatar?: string;
    /*
    personalBests: Result[];
    personalBestsFive: Result[];
    personalBestsTen: Result[];
    personalBestsTenTwo: Result[];
    personalBestsTenTwoTwo: Result[];
     */
    raceResults: RaceResult[];
}

 type UserInfo = UserInfoBase | undefined;

const UserInfoContainer = styled(ContainerBasedOnPaddings)`
  margin-top: 5rem;
  margin-bottom: 5rem;
`;
const UserInfoWrapper = styled.main`
  flex-grow: 1;
`;
const InfoWrapper = styled.section`
  display: flex;
  flex-direction: column;

  > span:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
const Title = styled.h2`
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 1rem;

  @media screen and (max-width: ${breakpoints.xs}px) {
    text-align: center;
  }
`;

const MainInfo = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${breakpoints.m}px) {
    flex-direction: column;
  }
`;
const MainInfoLeft = styled.div`
  display: flex;
  border-left: 10px solid #fe13a0;
  border-bottom: 5px solid #fe13a0;
  @media screen and (max-width: ${breakpoints.m}px) {
    justify-content: space-between;
  }

  @media screen and (max-width: ${breakpoints.xs}px) {
    flex-direction: column;
    align-items: center;
  }
`;
const UserMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8rem;
  border-right: 10px solid #fe13a0;
  
  @media screen and (max-width: ${breakpoints.xs}px) {
    margin-left: 0;
    margin-top: 2rem;
  }
`;

const PersonalBestsWrapper = styled.ul`
 
  &:not(:last-child) {
    border-left: 10px solid #fe13a0;
    border-bottom: 5px solid #fe13a0;
  }

` ;
const PersonalBest = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid ${({theme: {colors}}) => colors.black};
  }
`;
const PhotoAndSocialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const SocialsList = styled.ul`
  display: flex;
  margin-top: 2rem;

  @media screen and (max-width: ${breakpoints.xs}px) {
    justify-content: center;
  }
`;
const SocialsListItem = styled.li`
  margin-right: 2rem;
`;
const SocialsListLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fe13a0;
  color: #151515;
  height: 9rem;
  font-size: 35px;
  width: 9rem;
  border-radius: 50%;
  transition: ${({theme: {transition}}) => transition};

  &:hover {
    color: ${({theme: {colors}}) => colors.red};
  }
`;

const PersonalInfo = styled.span`
  color: ${({theme: {colors}}) => colors.grayii};
  font-size: 1.6rem;
  line-height: 1;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
const Distance = styled.span``;
const Time = styled.span``;
const SecondaryInfo = styled.div`
  display: flex;
  margin-top: 9rem;

  @media screen and (max-width: ${breakpoints.xs}px) {
    flex-direction: column;
    margin-top: 4rem;
  }
`;
const SecondaryInfoBlock = styled.div`
  border-left: 10px solid ${({theme: {colors}}) => colors.yellow};
  border-bottom: 5px solid ${({theme: {colors}}) => colors.yellow};
  display: flex;
  flex-direction: column;
  padding-left: 1rem;

  &:not(:last-child) {
    margin-right: 7rem;
  }

  @media screen and (max-width: ${breakpoints.xs}px) {
    &:not(:last-child) {
      margin-bottom: 2rem;
      margin-right: 0;
    }
  }
`;
const SecondaryInfoBlockTitle = styled.h3`
  font-weight: normal;
  margin-bottom: 1rem;
  font-size: 2.4rem;
`;
const SecondaryInfoBlockValue = styled.span`
  font-weight: bold;
  font-size: 3rem;
`;
const RaceResults = styled(ContainerBasedOnPaddings)`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.m}px) {
    max-width: 100%;
  }
`;
const RacesWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
  border-bottom: 1px solid #fe13a0;
`;

export const UserInfo: FC = () => {
    const [userInfo, setUserInfo] = useState<UserInfo>();
    const [loaded, setLoaded] = useState(true);
    const {id} = useParams();

    useEffect(() => {
        if (loaded) {
            api.getUserInfo(id).then(({data}) => {
                setUserInfo(data);
                setLoaded(false);
            });
        }
    }, [loaded, userInfo, id]);

    /*
    const showPersonalRecords = () => {
        const filteredPRs = userInfo
            ? userInfo.personalBests.filter((pr: Result) => pr.moving_time !== '00:00')
            : [];
        const filteredPRsFive = userInfo
            ? userInfo.personalBestsFive.filter((pr: Result) => pr.moving_time !== '00:00')
            : [];
        const filteredPRsTen = userInfo
            ? userInfo.personalBestsTen.filter((pr: Result) => pr.moving_time !== '00:00')
            : [];

        const filteredPRsTenTwo = userInfo
            ? userInfo.personalBestsTenTwo.filter((pr: Result) => pr.moving_time !== '00:00')
            : [];

        const filteredPRsTenTwoTwo = userInfo
            ? userInfo.personalBestsTenTwoTwo.filter((pr: Result) => pr.moving_time !== '00:00')
            : [];

        return (
            <>

                <PersonalBestsWrapper>
                    {filteredPRs.length !== 0 ? (
                        filteredPRs.map((pr: Result, index) => (
                            <PersonalBest key={index}>
                                <Distance>{pr.distance} KM</Distance> <Time>{moment.utc(moment.duration(pr.moving_time, 'seconds').as('milliseconds')).format('HH:mm:ss')}</Time>
                            </PersonalBest>
                        ))
                    ) : (
                        <span></span>
                    )}
                </PersonalBestsWrapper>
                <PersonalBestsWrapper>
                    {filteredPRsFive.length !== 0 ? (
                        filteredPRsFive.map((pr: Result, index) => (
                            <PersonalBest key={index}>
                                <Distance>{pr.distance} KM</Distance> <Time>{moment.utc(moment.duration(pr.moving_time, 'seconds').as('milliseconds')).format('HH:mm:ss')}</Time>
                            </PersonalBest>
                        ))
                    ) : (
                        <span></span>
                    )}
                </PersonalBestsWrapper>
                <PersonalBestsWrapper>
                    {filteredPRsTen.length !== 0 ? (
                        filteredPRsTen.map((pr: Result, index) => (
                            <PersonalBest key={index}>
                                <Distance>{pr.distance} KM</Distance> <Time>{moment.utc(moment.duration(pr.moving_time, 'seconds').as('milliseconds')).format('HH:mm:ss')}</Time>
                            </PersonalBest>
                        ))
                    ) : (
                        <span></span>
                    )}
                </PersonalBestsWrapper>
                <PersonalBestsWrapper>
                    {filteredPRsTenTwo.length !== 0 ? (
                        filteredPRsTenTwo.map((pr: Result, index) => (
                            <PersonalBest key={index}>
                                <Distance>Half marathon</Distance> <Time>{moment.utc(moment.duration(pr.moving_time, 'seconds').as('milliseconds')).format('HH:mm:ss')}</Time>
                            </PersonalBest>
                        ))
                    ) : (
                        <span></span>
                    )}
                </PersonalBestsWrapper>
                <PersonalBestsWrapper>
                    {filteredPRsTenTwoTwo.length !== 0 ? (
                        filteredPRsTenTwoTwo.map((pr: Result, index) => (
                            <PersonalBest key={index}>
                                <Distance>Marathon</Distance> <Time>{moment.utc(moment.duration(pr.moving_time, 'seconds').as('milliseconds')).format('HH:mm:ss')}</Time>
                            </PersonalBest>
                        ))
                    ) : (
                        <span></span>
                    )}
                </PersonalBestsWrapper>
            </>
        );
    };

     */

    const showSecondaryPersonInfo = () => {
        const {club} = userInfo!;
        const {club_id} = userInfo!;
        const {kilometersOverall} = userInfo!;
        const {kilometersThisMonth} = userInfo!;
        const {movingTimeThisMonth} = userInfo!;

        let timeInSeconds = movingTimeThisMonth;
        let distanceInKm = kilometersThisMonth;
        let paceInKm = timeInSeconds / distanceInKm;
        let minutesPaceInKm = paceInKm / 60;
        let secondsPaceInKm = paceInKm % 60;

        const  paceKm = `${Math.floor(minutesPaceInKm)}:${(secondsPaceInKm >= 0 && secondsPaceInKm < 10) ? "0" : ""}${Math.floor(secondsPaceInKm)} хв/км`


        return (
            <SecondaryInfo>
                {club !== undefined && (
                    <SecondaryInfoBlock>
                        <SecondaryInfoBlockTitle>Клуб</SecondaryInfoBlockTitle>
                        <SecondaryInfoBlockValue>
                            <a href={`/club/info/${club_id}`}>{club}</a>
                        </SecondaryInfoBlockValue>
                    </SecondaryInfoBlock>
                )}
                {kilometersOverall !== undefined && (
                    <SecondaryInfoBlock>
                        <SecondaryInfoBlockTitle>Всього кілометрів</SecondaryInfoBlockTitle>
                        <SecondaryInfoBlockValue>
                            {kilometersOverall}
                        </SecondaryInfoBlockValue>
                    </SecondaryInfoBlock>
                )}
                {kilometersThisMonth !== undefined && (
                    <SecondaryInfoBlock>
                        <SecondaryInfoBlockTitle>За місяць</SecondaryInfoBlockTitle>
                        <SecondaryInfoBlockValue>
                            {kilometersThisMonth}
                        </SecondaryInfoBlockValue>
                    </SecondaryInfoBlock>
                )}
                {movingTimeThisMonth !== undefined && (
                    <SecondaryInfoBlock>
                        <SecondaryInfoBlockTitle>Ср. темп</SecondaryInfoBlockTitle>
                        <SecondaryInfoBlockValue>
                            {paceKm}
                        </SecondaryInfoBlockValue>
                    </SecondaryInfoBlock>
                )}

            </SecondaryInfo>
        );
    };

    const showRaceResults = () => {
        if (userInfo && userInfo.raceResults) {
            const {raceResults} = userInfo;

            return (
                <>
                    <SectionTitle>Останні тренування</SectionTitle>
                    <RaceResults>
                        <RacesWrapper>
                            {raceResults.map((raceResult: RaceResult) => (
                                <UserLastRun raceInfo={raceResult}/>
                            ))}
                        </RacesWrapper>
                    </RaceResults>
                </>
            );
        }
        return null;
    };

    const getUserInfo = () => {
        if (userInfo && !loaded) {
            const {
                athlete_firstname,
                athlete_lastname,
                birthday,
                city,
                instagram,
                facebook,
                strava,
                athlete_picture_url
            } = userInfo;

            const birthDay = moment(birthday).format('MM/DD/YYYY');

            return (

                <InfoWrapper>
                    <MainInfo>
                        <MainInfoLeft>
                            <PhotoAndSocialsWrapper>
                                <UserPhoto avatar={athlete_picture_url}/>
                                <SocialsList>
                                    {instagram && (
                                        <SocialsListItem>
                                            <SocialsListLink target="_blank" href={instagram}>
                                                <FaInstagram/>
                                            </SocialsListLink>
                                        </SocialsListItem>
                                    )}
                                    {facebook && (
                                        <SocialsListItem>
                                            <SocialsListLink target="_blank" href={facebook}>
                                                <FaFacebookF/>
                                            </SocialsListLink>
                                        </SocialsListItem>
                                    )}
                                    {strava && (
                                        <SocialsListItem>
                                            <SocialsListLink href={strava}>
                                                <FaStrava/>
                                            </SocialsListLink>
                                        </SocialsListItem>
                                    )}
                                </SocialsList>
                            </PhotoAndSocialsWrapper>
                            <UserMainInfo>
                                <Title>{athlete_firstname} {athlete_lastname}</Title>
                                {birthday && <PersonalInfo>{birthDay}</PersonalInfo>}
                                {city && <PersonalInfo>Украина, {city}</PersonalInfo>}

                            </UserMainInfo>
                        </MainInfoLeft>

                    </MainInfo>
                    {showSecondaryPersonInfo()}
                </InfoWrapper>
            );
        } else {
            return <Loader/>;
        }
    };

    /**
     *       <UserInfoContainer>{showPersonalRecords()}</UserInfoContainer>
     */
    return (
        <>
            <HeaderBackground/>
            <UserInfoWrapper>
                <UserInfoContainer>{getUserInfo()}</UserInfoContainer>

            </UserInfoWrapper>
            {showRaceResults()}
            <Footer/>
        </>
    );
};
