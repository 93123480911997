import React, {FC, useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {useMediaQuery, useThrottledFn, useWindowResize, useWindowScroll,} from 'beautiful-react-hooks';

import {Logo} from './logo';

import {ContainerBasedOnPaddings} from '../../sematic/common';
import {breakpoints} from '../../sematic/breakpoints';

import {MobileNavigation} from '../module/menu/mobileNavigation';
import {Navigation} from '../module/menu/navigation';
import {Scroller} from '../module/scroller/scroller';
import {DebouncedFunc} from "beautiful-react-hooks/shared/types";

interface HeaderWrapperProps {
    /**
     * True if user scroll to bottom
     */
    isScrolled: boolean;
    /**
     * True if it's a mobile viewport
     */
    isMobileView?: boolean;
}

interface HeaderProps {
    /**
     * Active page name
     */
    activePage: number;
}

const HeaderWrapper = styled.div<HeaderWrapperProps>`
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1001;
  width: 100%;

  transition: ${props => props.theme.transition};
  overflow: hidden;
  background-color: ${({isScrolled, theme: {colors}}) =>
    isScrolled ? colors.black : colors.transparent};

 

@media (max-width: ${breakpoints.s}px) {
  background-color: ${({isScrolled, theme: {colors}}) =>
    isScrolled ? colors.black : colors.black};
}

  
  ${({isScrolled}) =>


    isScrolled ? css`
      .st0{fill:#fe13a0;}
      .st1{fill:#ffffff;}
    ` : css`

      .st0{fill:#fe13a0;}
      .st1{fill:#ffffff;}

    `}; 
    `;

const HeaderContainer = styled(ContainerBasedOnPaddings)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  @media screen and (max-width: ${breakpoints.s}px) {
    max-width: unset;
  }
`;

export const Header: FC<HeaderProps> = ({activePage}) => {
    const HEADER_HEIGHT = 100;

    const [scrollY, setScrollY] = useState(window.pageYOffset);
    const [isScrolled, setScrolled] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const isMobileView = true;
    const [isBurgerOpen, setBurgerOpen] = useState(false);

    const onBurgerClick = () => setBurgerOpen(!isBurgerOpen);

    const onWindowResizeHandler: DebouncedFunc<() => void> = useThrottledFn(() =>
        setWidth(window.innerWidth)
    );

    useWindowResize(onWindowResizeHandler);

    useWindowScroll(() => setScrollY(window.pageYOffset));

    useEffect(() => {
        setScrolled(scrollY > HEADER_HEIGHT);
        return () => onWindowResizeHandler.cancel();
    }, [onWindowResizeHandler, scrollY]);

    return (
        <>
            <HeaderWrapper
                id="header"
                isScrolled={isScrolled}
                isMobileView={isMobileView}
            >
                <Scroller isScrolled={isScrolled}/>
                <HeaderContainer>
                    <Logo isScrolled={isScrolled} hideText={width < breakpoints.s}/>
                    <Navigation
                        activePage={activePage}
                        isScrolled={isScrolled}
                        isMobileView={isMobileView}
                        onBurgerClick={onBurgerClick}
                        isBurgerOpen={isBurgerOpen}
                    />
                </HeaderContainer>
            </HeaderWrapper>
            {isMobileView && (
                <MobileNavigation activePage={activePage} isBurgerOpen={isBurgerOpen}
                                  closeBurger={() => setBurgerOpen(false)}/>
            )}
        </>
    );
};
