import React, {FC} from 'react';
import styled from 'styled-components';
import {FaArrowUp} from 'react-icons/fa';
import {InstagramProps} from "./component/InstagramProps";




const Media = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transform: none;
  height: 320px;
  width: 100%;
  margin-bottom: 1em;
  position: relative;
  box-shadow: 0 10px 10px 1px rgba(254, 19, 160, 0.20);
  transition: ${props => props.theme.transition};

  &:hover,
  &:focus {
    box-shadow: 0 2px 10px 1px rgba(254, 19, 160, 1);

    & svg {
      transition: ${props => props.theme.transition};
      transition-delay: 0.1s;
      transform: none;
      opacity: 1;
    }
  }
`;

const MediaImage = styled.img`
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;

  background-color: rgba(0, 0, 0, 0.4);

  opacity: 0;
  transition: ${props => props.theme.transition};

  &:hover {
    opacity: 1;
  }
`;

const OverlayInfo = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 2rem;
  text-transform: uppercase; 

  color: ${props => props.theme.colors.white};

  > svg {
    transform: translateY(10px);
    opacity: 0;
    margin-right: 1rem;
  }
`;

export const InstaGrid: FC<InstagramProps> = ({permalink, media_url}) => {
    return (
        <Media href={permalink} target="__blank">
            <MediaImage src={media_url} alt="LRD "/>
            <Overlay>
                <OverlayInfo>
                    <FaArrowUp/>
                    Передивитись
                </OverlayInfo>
            </Overlay>
        </Media>
    );
};


