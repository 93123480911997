import React, {FC} from 'react';

import {HeaderBackground} from '../../headerbg/HeaderBackground';
import {Footer} from '../../../common/footer';
import {InstagramMedia} from '../../instagram/InstagramMedia';
import {WeekRating} from '../../top/WeekRating';
import {RaceDetails} from "../../races/RaceInfo";

export const MainPage: FC = () => {
    return (
        <>
            <HeaderBackground/>
            <RaceDetails />
            <InstagramMedia/>
            <WeekRating/>
            <Footer/>
        </>
    );
};
