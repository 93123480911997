import styled from "styled-components";
import {breakpoints} from "../../../sematic/breakpoints";
import React, {FC} from "react";
import {ClubProps} from "./component/ClubProps";

const Club = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transform: none;
  height: 350px;
  width: 65%;
  border-radius: 50%;
  margin-bottom: 1em;
  position: relative;
  box-shadow: 0 10px 10px 1px rgba(254, 19, 160, 0.20);
  transition: ${props => props.theme.transition};

  &:hover,
  &:focus {
    box-shadow: 0 2px 10px 1px rgba(254, 19, 160, 1);

    & svg {
      transition: ${props => props.theme.transition};
      transition-delay: 0.1s;
      transform: none;
      opacity: 1;
    }
  }

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    width: auto;
  }
  @media screen and (max-width: ${`${breakpoints.m}px`}) {
    width: auto;
  }
`;

const ClubSpan = styled.span ` 
  display: flex; 
  flex-direction: column;
  overflow: hidden; 
  transform: none; 
  width: 65%; 
  margin-bottom: 1em;
  position: relative;
  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    width: auto;
  }
  @media screen and (max-width: ${`${breakpoints.m}px`}) {
    width: auto;
  }
`;

const ClubImage = styled.img`
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const ClubList: FC<ClubProps> = ({permalink, club_name,runners,club_avatar}) => {

    const list = '/club/info/';

    return (
        <>
            <Club href={list+permalink} >
                <ClubImage src={club_avatar} alt={club_name}/>

            </Club>
            <ClubSpan>Назва: {club_name} </ClubSpan>
            <ClubSpan>Учасників: {runners} </ClubSpan>
        </>
    );
};
