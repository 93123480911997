import React, {FC, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Footer} from '../../../common/footer';
import {breakpoints} from '../../../../sematic/breakpoints';
import api from '../../../../axiosApi';
import {SectionTitle} from '../../../common/section-title';
import {Loader} from "../../loader/loader";
import {HeaderBackground} from "../../headerbg/HeaderBackground";
import {ContainerBasedOnPaddings} from "../../../../sematic/common";
import {AboutData} from "../component/AboutData";

const AboutContent = styled.main`
  flex-grow: 1;
  margin-top: 2rem;
`;

const AboutContainer = styled(ContainerBasedOnPaddings)``;

const AboutTitle = styled(SectionTitle)`
  margin-top: 3rem;
`;


const AboutWrapper = styled.section`
  display: flex;
  margin-bottom: 2rem;

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    flex-direction: column;
    margin-bottom: 4rem;
  }
`;

const AboutMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: ${breakpoints.s}px) {
    align-items: center;
  }
`;

export const About: FC = () => {
    const [about, setAbout] = useState<AboutData | undefined>(undefined);
    const [loaded, setLoaded] = useState(false);

    const parse = require('html-react-parser');

    useEffect(() => {
        api
            .getAbout()
            .then(({data}) => {
                setAbout(data);
                setLoaded(true);
            })
            .catch(e => console.log(e));
    }, []);

    return (
        <>
            <HeaderBackground/>

            <AboutTitle direction="ltr" inOneLine>
                Про нас
            </AboutTitle>
            <AboutContent>
                <AboutContainer>
                    <AboutWrapper>
                        <AboutMain>
                            {loaded ? parse(about?.content) : <Loader/>}
                        </AboutMain>
                    </AboutWrapper>
                </AboutContainer>
            </AboutContent>
            <Footer/>
        </>
    );
};
